import React, { useEffect, useState } from "react";

const SubjectHeaderMenu = ({
  studyDynamicColumn,
  cleanForAddNewSiteData,
  setUpdatedType,
  tableProperty,
  filterData,
  studyDynamicRow,
  addTableAndRegularView,
  updatedType,
  setFilter,
  filter,
  getStudySubjectRowData,
  breadcrumbData,
  handleAddNewTab,
}) => {
  const StudyMode = JSON.parse(localStorage.getItem("StudyMode"));

  return (
    <>
      {breadcrumbData?.length > 0 && (
        <div className="border-bottom" style={{ height: 20 }}>
          <ul id="breadcrumb">
            {breadcrumbData?.map((item, index) => (
              <li key={index}>
                {breadcrumbData?.length === index + 1 ?
                  <a>{item?.tabName}</a>
                  :
                  <a onClick={() => handleAddNewTab(item?.currentTabName, "fa-solid fa-database", item?.uniqueId, item?.pageId, item?.breadcrumb)}>{item?.tabName}</a>
                }
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="p-1 hstack justify-content-between border-bottom">
        <div className="hstack gap-2 toolbar">
          {studyDynamicColumn?.length > 0 &&
            (StudyMode === "DBP MODE" ||
              StudyMode === "Live Mode" ||
              StudyMode === "QC Mode") && (
              <>
                {updatedType !== "addRow" ? (
                  <i
                    onClick={cleanForAddNewSiteData}
                    title="Add Site Row"
                    className="fa-solid fa-plus-square CP"
                  />
                ) : (
                  <i
                    onClick={() => setUpdatedType("")}
                    title="Table Full Mode"
                    className="fa-solid fa-table-list"
                  ></i>
                )}
                <div className="vr"></div>
                {updatedType === "editRow" && (
                  <>
                    <i
                      onClick={() => setUpdatedType("")}
                      title="Table Full Mode"
                      className="fa-solid fa-table-list"
                    ></i>
                    <div className="vr"></div>
                  </>
                )}
              </>
            )}
          {studyDynamicColumn?.length > 0 && (
            <>
              <i
                onClick={tableProperty}
                title="Table Properties"
                className="fa-solid fa-list CP"
              />
              <div className="vr"></div>
            </>
          )}
          {studyDynamicRow?.length > 0 && (
            <>
              <i
                onClick={filterData}
                title="Filter"
                className="fa-solid fa-filter CP"
              />
            </>
          )}
          {filter && (
            <i
              onClick={() => {
                setFilter(false);
                getStudySubjectRowData();
              }}
              title="Clear Filter"
              className="fa-solid fa-filter-circle-xmark CP red"
            />
          )}
        </div>
        <button
          className="m-0 CP bg-main rounded-1 text-white hstack"
          onClick={(e) => addTableAndRegularView(e, "regular")}
          title="Switch to Grid View"
          style={{ fontSize: 13 }}
        >
          <i className="fa-solid fa-toggle-off me-2"></i>
          Grid View
        </button>
      </div>
    </>
  );
};

export default SubjectHeaderMenu;
