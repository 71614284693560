import { createContext, useContext, useState } from "react";

export const CrfEditorUpdateDataContext = createContext();

export const CrfEditorUpdateDataContextProvider = ({ children }) => {
  const [newData, setNewData] = useState([]);

  const handleChangeNewData = (e, i) => {
    const { name, value } = e.target;

    setNewData((prevValues) => {
      const newValues = [...prevValues];
      newValues[i][name] = value;
      return newValues;
    });
  };

  const removeNewData = (i) => {
    setNewData((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };

  const contextValue = {
    newData,
    setNewData,
    handleChangeNewData,
    removeNewData,
  };

  return (
    <CrfEditorUpdateDataContext.Provider value={contextValue}>
      {children}
    </CrfEditorUpdateDataContext.Provider>
  );
};

export const UseCrfEditorUpdateDataContextContext = () =>
  useContext(CrfEditorUpdateDataContext);
