import config from "../../config/config.json";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const studySettingControlServices = {
  addQuerySubject,
  getQuerySubjectList,
  updateQuerySubjectData,
  updateStatusQuerySubjectData,
  getActiveQuerySubjectList,
  deleteQuerySubjectData,
  addQueryMessage,
  getQueryMessageList,
  updateQueryMessageData,
  updateStatusQueryMessageData,
  getActiveQueryMessageList,
  deleteQueryMessageData,
  addQueryResponseMessage,
  getQueryResponseMessageList,
  updateQueryResponseMessageData,
  updateStatusQueryResponseMessageData,
  getActiveQueryResponseMessageList,
  deleteQueryResponseMessageData,
};
//Add Query Subject
async function addQuerySubject(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addQuerySubject`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Update Query Subject
async function updateQuerySubjectData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateQuerySubjectData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Update Status Query Subject
async function updateStatusQuerySubjectData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStatusQuerySubjectData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

//Delete Query Subject
async function deleteQuerySubjectData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteQuerySubjectData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}

//Get Query Subject
async function getQuerySubjectList(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getQuerySubjectList/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Get Active Query Subject
async function getActiveQuerySubjectList(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveQuerySubjectList/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Add Query Message
async function addQueryMessage(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addQueryMessage`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Update Query Message
async function updateQueryMessageData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateQueryMessageData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Update Status Query Message
async function updateStatusQueryMessageData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStatusQueryMessageData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Delete Query Message
async function deleteQueryMessageData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteQueryMessageData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}

//Get Query Message
async function getQueryMessageList(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getQueryMessageList/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Get Active Query Message
async function getActiveQueryMessageList(studyId, subjectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveQueryMessageList/${studyId}/${subjectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Add Query Response Message
async function addQueryResponseMessage(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addQueryResponseMessage`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Update Query Response Message
async function updateQueryResponseMessageData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateQueryResponseMessageData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Update Status Query Response Message
async function updateStatusQueryResponseMessageData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStatusQueryResponseMessageData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Delete Query Response Message
async function deleteQueryResponseMessageData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteQueryResponseMessageData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}

//Get Query Response Message
async function getQueryResponseMessageList(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getQueryResponseMessageList/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Get Active Query Response Message
async function getActiveQueryResponseMessageList(studyId, subjectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getActiveQueryResponseMessageList/${studyId}/${subjectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}