import React, { useEffect, useState } from "react";
import SplitPane from "react-split-pane";
import { useParams } from "react-router";
import AddLearning from "./AddLearning";
import ELearningDataTable from "./ELearningDataTable";
import ELearningProperties from "./ELearningProperties";
import { elearningServices } from "../../../Services/elearningServices";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../NoDataFoundTable/NoDataFoundTable";
import UpdateLearning from "./UpdateLearning";

const ELearningManagement = ({ handleAddNewTab, viewTypeData }) => {
  const { studyId } = useParams();
  const [loader, setLoader] = useState(false);
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [updateType, setUpdateType] = useState("");
  const [trainingId, setTrainingId] = useState("");
  const [trainingData, setTrainingData] = useState("");
  const StudyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const [elearningData, setElearningData] = useState([]);

  const getStudyELearningData = async () => {
    let data = await elearningServices.getStudyELearningData(studyId);
    setElearningData(data?.data);
  };

  const handleRowClick = (id) => {
    setTrainingId(id);
    setIsSectionVisible(true);
  };

  const handleUpdate = (item) => {
    setUpdateType("update");
    setTrainingData(item);
  };

  useEffect(() => {
    getStudyELearningData();
  }, []);

  return (
    <>
      <div className="position-relative">
        {StudyMode === "DBP MODE" && (
          <div className="p-1 hstack justify-content-between border-bottom">
            <div className="hstack gap-2 toolbar">
              {viewTypeData === "ctms" &&
                (updateType ? (
                  <i
                    onClick={() => setUpdateType("")}
                    title="Table Full Mode"
                    className="fa-solid fa-table-list"
                  ></i>
                ) : (
                  <i
                    onClick={() => setUpdateType("add")}
                    title="Add new E-Learning Data"
                    className="fa-solid fa-square-plus CP"
                  />
                ))}
              <div className="vr"></div>
              <i title="Table Properties" className="fa-solid fa-list CP" />
            </div>
          </div>
        )}
        <SplitPane
          split="horizontal"
          style={{
            height: "calc(100vh - 187px)",
          }}
          className="position-relative"
          defaultSize={isSectionVisible ? "40%" : "100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={updateType ? "20%" : "100%"}
          >
            {loader ? (
              <SpinerDataLoader />
            ) : updateType === "add" ? (
              <AddLearning
                getStudyELearningData={getStudyELearningData}
                studyId={studyId}
              />
            ) : (
              updateType === "update" && (
                <UpdateLearning
                  getStudyELearningData={getStudyELearningData}
                  studyId={studyId}
                  trainingData={trainingData}
                />
              )
            )}
            {loader ? (
              <SpinerDataLoader />
            ) : elearningData?.length > 0 ? (
              <ELearningDataTable
                getStudyELearningData={getStudyELearningData}
                handleAddNewTab={handleAddNewTab}
                elearningData={elearningData}
                handleRowClick={handleRowClick}
                handleUpdate={handleUpdate}
                viewTypeData={viewTypeData}
                trainingId={trainingId}
              />
            ) : (
              <NoDataFoundTable MSG="Data not found" />
            )}
          </SplitPane>
          {isSectionVisible && (
            <ELearningProperties
              viewTypeData={viewTypeData}
              trainingId={trainingId}
              setIsSectionVisible={setIsSectionVisible}
            />
          )}
        </SplitPane>
      </div>
    </>
  );
};

export default ELearningManagement;
