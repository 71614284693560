import React, { useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";

const NewTrialDesignModal = ({ SHOW, HIDE, TITLE, onAddFactor }) => {
  
  const [name, setName] = useState('');

  const handleAdd = () => {
    if (setName) {
      onAddFactor(setName);
      setName(''); 
    }
  };
  return (
    <>
      <Modal show={SHOW} className={SHOW ? "" : "d-none"} onHide={HIDE}>
        <Modal.Header closeButton>
          <Modal.Title>{TITLE}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3 d-flex gap-1">
            <Col md={2} className="mb-2">
              <Form.Label>
                <b>Name:</b>
              </Form.Label>
            </Col>
            <Col md={10} className="mb-2">
              <Form.Control type="text" 
              value={name}
              onChange={(e) => setName(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group className="mb-1 d-flex gap-1">
            <Col md={2} className="mb-2">
              <Form.Label>
                <b>Weight:</b>
              </Form.Label>
            </Col>
            <Col md={10} className="mb-2">
              <Form.Control type="number" />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <div className=" text-end mb-1">
            <Button
              className="updateProfile"
              style={{ width: 100, fontSize: "14px", marginRight: "5px" }}
              onClick={handleAdd}
            >
              Add
            </Button>
            <Button
              className="updateProfile"
              style={{ width: 100, fontSize: "14px", marginRight: "5px" }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewTrialDesignModal;
