import React, { useState } from "react";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import { Table } from "react-bootstrap";
import SplitPane from "react-split-pane";
import { SpinerDataLoader } from "../../NoDataFoundTable/NoDataFoundTable";

const RandomizeTabPropertiesData = ({ onMouseDown, tableRef }) => {
    const [loader, setLoadeer] = useState("");
    return (
        <>
            <SplitPane
                split="vertical"
                minSize={20}
                defaultSize="100%"
                style={{ height: "91%" }}
            >
                {loader ? (
                    <SpinerDataLoader />
                ) : (
                    <div className="table-container tableLibrarySection">
                        <Table
                            id="resizable-table" striped
                            className="m-0 custom-table"
                            ref={tableRef}
                            bordered
                        >
                            <thead className="thead-sticky" style={{ top: -1 }}>
                                <tr>
                                    <th onMouseDown={(e) => onMouseDown(e, 0)}>
                                        Attribute
                                    </th>
                                    <th onMouseDown={(e) => onMouseDown(e, 1)}>
                                        Value
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Randomization No.</td>
                                    <td>8</td>
                                </tr>

                                <tr>
                                    <td>Site</td>
                                    <td>SITE04: site04</td>
                                </tr>

                                <tr>
                                    <td>Subject-ID</td>
                                    <td>000002</td>
                                </tr>
                                <tr>
                                    <td>Random Number</td>
                                    <td>000003</td>
                                </tr>

                                <tr>
                                    <td>Treatment Code</td>
                                    <td >Treat001</td>
                                </tr>

                                <tr>
                                    <td>Treatment Level</td>
                                    <td className="text-danger">Reference</td>
                                </tr>
                                <tr>
                                    <td>Gender</td>
                                    <td> male</td>
                                </tr>
                                <tr>
                                    <td>Remarks</td>
                                    <td>randomize 4</td>
                                </tr>
                                <tr>
                                    <td>Randomized on</td>
                                    <td>
                                        <Moment format="DD MMM YYYY | HH:mm" withTitle>
                                            2024-05-26 07:03:43 UTC
                                        </Moment>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Randomized by</td>
                                    <td style={{ fontWeight: "normal" }}>
                                        <NavLink
                                            to={"/"}
                                            className="randomizer-link"
                                            style={{ color: "blue", textDecoration: "none" }}
                                        >
                                            Gaurav Chauhan
                                        </NavLink>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td className="text-success">Blinding</td>
                                </tr>
                                <tr>
                                    <td>Request</td>
                                    <td >Pending</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                )}
            </SplitPane>
        </>
    );
};

export default RandomizeTabPropertiesData;
