/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import SplitPane from "react-split-pane";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../../NoDataFoundTable/NoDataFoundTable";
import UseToggle from "../../../../../../Hooks/UseToggle";
import { BsFillTicketPerforatedFill } from "react-icons/bs";
import { toastProps } from "../../../../../../Helpers/Responses";
import { clientLevelFormServices } from "../../../../../../Services/formServices/clientLevelFormServices";
import {
  Col,
  Form,
  Modal,
  Offcanvas,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { studySettingControlServices } from "../../../../../../Services/studyServices/studySettingControlServices";
import { regularFormDataEntryServices } from "../../../../../../Services/studyServices/regularFormDataEntryServices";
import {
  BiSolidCommentCheck,
  BiSolidCommentDetail,
  BiSolidCommentEdit,
  BiSolidCommentX,
} from "react-icons/bi";

export const QueryTable = ({
  i,
  dataEntryMode,
  studyUniqueId,
  visitFormType,
  getTicketsEndPoint,
  selectedFormId,
  selectedForm,
  formUniqueId,
  getformFieldAttributes,
  formType,
  getdataEntryFormStatus,
  connectUniqueIdData,
  getLogFormListForRegular,
}) => {
  console.log(">>>>studyUniqueId", studyUniqueId);

  const {
    toggle2,
    setToggle2,
    toggle3,
    setToggle3,
    toggle4,
    setToggle4,
    toggle5,
    setToggle5,
    toggle6,
    setToggle6,
    toggle7,
    setToggle7,
  } = UseToggle();

  const StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const { studyId } = useParams();

  const [ticketList, setTicketList] = useState([]);
  const [loader, setLoader] = useState(false);

  const [formFieldStatus, setFormFieldStatus] = useState([]);
  const [queryStatus, setQueryStatus] = useState("");

  const getGlobalTickets = async () => {
    setLoader(true);
    let endPointFunction =
      formType === "studyform"
        ? await clientLevelFormServices.getGlobalTickets(
            getTicketsEndPoint,
            formUniqueId,
            selectedFormId
          )
        : connectUniqueIdData
        ? await clientLevelFormServices.getDataEntryTicketsLogForm(
            "getDataEntryTicketsLogForm",
            formUniqueId,
            StudyMode,
            dataEntryMode,
            studyUniqueId,
            selectedFormId,
            connectUniqueIdData
          )
        : await clientLevelFormServices.getDataEntryTickets(
            "getDataEntryTickets",
            formUniqueId,
            StudyMode,
            dataEntryMode,
            studyUniqueId,
            selectedFormId
          );
    const res = endPointFunction;
    setTicketList(res?.data);
    setLoader(false);
  };

  const getFieldAttribute = async () => {
    const res = await clientLevelFormServices.getFormFieldAttributeByID(
      getformFieldAttributes,
      formUniqueId,
      selectedFormId
    );
    setFormFieldStatus(res?.data?.status);
  };

  useEffect(() => {
    if (getformFieldAttributes) {
      getFieldAttribute();
    }
  }, [getformFieldAttributes]);

  useEffect(() => {
    getGlobalTickets();
  }, [formUniqueId, selectedFormId, getTicketsEndPoint, connectUniqueIdData]);

  const studyPermissionData = JSON.parse(
    localStorage.getItem("studyPermissionData")
  );
  const [generateQueryType, setGenerateQueryType] = useState("");
  const [queryMessage, setQueryMessage] = useState("");
  const [visitName, setVisitName] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [siteName, setSiteName] = useState("");
  const [queries, setQueries] = useState([]);
  const [querySendingType, setQuerySendingType] = useState("");
  const [queryTypeId, setQueryTypeId] = useState("");
  const [queryResponseMessageList, setQueryResponseMessageList] = useState([]);
  const [queryId, setQueryId] = useState("");
  const [queryType, setQueryType] = useState("");
  const [formFieldName, setFormFieldName] = useState("");
  const [formFieldValue, setFormFieldValue] = useState("");
  const [formName, setFormName] = useState("");
  const [queryResponseTitle, setQueryResponseTitle] = useState("");
  const [validationData, setValidationData] = useState("");
  const [generatedBy, setGeneratedBy] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [status, setStatus] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [subjectType, setSubjectType] = useState("");
  const [messageType, setMessageType] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [messageList, setMessageList] = useState([]);

  const getDataEntryFormFieldValidation = async (
    recordId,
    ticketId,
    queryType,
    generatedBy,
    createdBy,
    status
  ) => {
    let data = await clientLevelFormServices.getDataEntryFormFieldValidation(
      formUniqueId,
      studyUniqueId,
      StudyMode,
      dataEntryMode,
      selectedFormId,
      studyId,
      recordId
    );
    setValidationData(data?.data);
    setToggle7();
    setQueryId(ticketId);
    setQueryType(queryType);
    setGeneratedBy(generatedBy);
    setCreatedBy(createdBy);
    setStatus(status);
  };

  const getQueryList = async (ticketId) => {
    const res = await clientLevelFormServices.getQueryList(
      "getDataEntryQuery",
      ticketId
    );
    setQueries((res?.data).reverse());
  };

  const openQueryList = async (e) => {
    let name = selectedForm?.label;
    let type = selectedForm?.type;

    let result = await regularFormDataEntryServices.findFormNameAndFieldName(
      studyId,
      selectedForm?.uniqueId,
      formUniqueId,
      studyUniqueId,
      dataEntryMode,
      StudyMode
    );
    let value = result?.formData?.value;
    let format = result?.formData?.format;
    setFormFieldName(name);
    setFormName(result?.formName);
    if (type === "checkbox") {
      setFormFieldValue(value.join(", "));
    } else if (type === "text" || type === "number") {
      setFormFieldValue(value?.value + " " + value?.unit);
    } else if (
      type === "textarea" ||
      type === "radio" ||
      type === "dropdown" ||
      type === "dateCalendar" ||
      type === "timeCalendar"
    ) {
      setFormFieldValue(value.value);
    } else if (type === "time") {
      {
        format === "HH:MM:SS"
          ? setFormFieldValue(
              value.hour +
                ":" +
                value.minute +
                ":" +
                value.second +
                " " +
                value.format
            )
          : format === "HH:MM"
          ? setFormFieldValue(
              value.year + ":" + value.month + " " + value.format
            )
          : setFormFieldValue("");
      }
    } else if (type === "date") {
      {
        format === "YYYY_MMM_DD"
          ? setFormFieldValue(value.year + " " + value.month + " " + value.day)
          : format === "YYYY/MMM/DD"
          ? setFormFieldValue(value.year + "/" + value.month + "/" + value.day)
          : format === "YYYY-MMM-DD"
          ? setFormFieldValue(value.year + "-" + value.month + "-" + value.day)
          : format === "YYYY_MM_DD"
          ? setFormFieldValue(value.year + " " + value.month + " " + value.day)
          : format === "YYYY/MM/DD"
          ? setFormFieldValue(value.year + "/" + value.month + "/" + value.day)
          : format === "YYYY-MM-DD"
          ? setFormFieldValue(value.year + "-" + value.month + "-" + value.day)
          : format === "DD_MMM_YYYY"
          ? setFormFieldValue(value.day + " " + value.month + " " + value.year)
          : format === "DD/MMM/YYYY"
          ? setFormFieldValue(value.day + "/" + value.month + "/" + value.year)
          : format === "DD-MMM-YYYY"
          ? setFormFieldValue(value.day + "-" + value.month + "-" + value.year)
          : format === "DD_MM_YYYY"
          ? setFormFieldValue(value.day + " " + value.month + " " + value.year)
          : format === "DD/MM/YYYY"
          ? setFormFieldValue(value.day + "/" + value.month + "/" + value.year)
          : format === "DD-MM-YYYY"
          ? setFormFieldValue(value.day + "-" + value.month + "-" + value.year)
          : setFormFieldValue("");
      }
    }
  };

  const openGenerateQueryModal = () => {
    setToggle3();
    getActiveQueryMessageList();
  };

  const closeGenerateQueryModal = () => {
    setToggle3();
    setGenerateQueryType("");
    setQueryMessage("");
  };

  const generateQueryMessageModal = (query) => {
    setGenerateQueryType(query);
    if (query === "custom") {
      setQueryMessage("");
    }
  };
  const getVisitDetails = async () => {
    let data = await regularFormDataEntryServices.getVisitDetails(
      studyId,
      studyUniqueId
    );
    setVisitName(data?.visitName);
    setSubjectName(data?.subjectName);
    setSiteName(data?.siteName);
  };

  const getActiveQuerySubjectList = async () => {
    let data = await studySettingControlServices.getActiveQuerySubjectList(
      studyId
    );
    setSubjectList(data?.data);
  };

  const getActiveQueryMessageList = async (subjectId) => {
    setSubjectId(subjectId);
    let data = await studySettingControlServices.getActiveQueryMessageList(
      studyId,
      subjectId
    );
    setMessageList(data?.data);
  };

  const getActiveQueryResponseMessageList = async () => {
    let data =
      await studySettingControlServices.getActiveQueryResponseMessageList(
        studyId,
        subjectId
      );
    setQueryResponseMessageList(data.data);
  };

  const createQuery = async () => {
    if (!message) toast.warn("Please Enter Query", toastProps);
    else {
      let endPointData = connectUniqueIdData
        ? "DataEntryQueryGeneratorLogForm"
        : "DataEntryQueryGenerator";
      let updateData = {
        uniqueId: formUniqueId,
        fieldId: selectedFormId,
        query: message,
        studyUniqueId: studyUniqueId,
        formType: visitFormType,
        dataEntryMode: dataEntryMode,
        connectUniqueId: connectUniqueIdData,
        subjectId: subjectId,
        studyMode: StudyMode,
        study_Id: studyId,
      };
      let data = await clientLevelFormServices.queryGenerator(
        endPointData,
        updateData
      );
      if (data?.statusCode) {
        setQueryMessage("");
        setToggle3();
        getGlobalTickets();
        getdataEntryFormStatus();
        getLogFormListForRegular();
      }
    }
  };

  const openSingleViewQueryModal = (id, type, status, subjectId) => {
    getQueryList(id);
    openQueryList();
    setQueryId(id);
    setQueryType(type);
    setQueryStatus(status);
    getVisitDetails();
    setToggle2();
    setSubjectId(subjectId);
  };

  const dataEntryQueryComment = async () => {
    await clientLevelFormServices.replyQuery("DataEntryQueryReply", {
      ticketId: queryId,
      query: queryMessage,
      study_Id: studyId,
      formUniqueId: formUniqueId,
    });
    setQueryMessage("");
    openSingleViewQueryModal(queryId, "manual", queryStatus);
    setToggle4();
  };

  const dataEntryQueryReply = async () => {
    await clientLevelFormServices.replyQuery("DataEntryQueryComment", {
      ticketId: queryId,
      query: queryMessage,
      study_Id: studyId,
      formUniqueId: formUniqueId,
    });
    setQueryMessage("");
    openSingleViewQueryModal(queryId, "manual", queryStatus);
    setToggle5();
  };

  const updateQueryStatus = async (e, queryStatus) => {
    await clientLevelFormServices.updateQuery("updateTicketStatusWithMessage", {
      ticketId: queryId,
      status: queryStatus,
      study_Id: studyId,
      formUniqueId: formUniqueId,
    });
    openSingleViewQueryModal(queryId, "manual", queryStatus);
    getGlobalTickets();
    getdataEntryFormStatus();
    getLogFormListForRegular();
  };

  const updateCommentQuery = async (e) => {
    await clientLevelFormServices.updateCommentQuery({
      ticketId: queryId,
      queryId: queryTypeId,
      query: queryMessage,
      type: querySendingType,
      study_Id: studyId,
      formUniqueId: formUniqueId,
    });
    openSingleViewQueryModal(queryId, "manual", queryStatus);
    setToggle6();
  };

  const updateCommentQueryModal = async (e, id, type, query) => {
    setToggle6();
    setToggle2();
    setQueryMessage(query);
    setQuerySendingType(type);
    setQueryTypeId(id);
  };

  const commentorResponse = (title) => {
    setQueryResponseTitle(title);
    getActiveQueryResponseMessageList();
    setToggle2();
    setToggle4();
  };

  const closeQueryCommenterResponseModal = () => {
    setToggle2();
    setToggle4();
    setGenerateQueryType("");
    setQueryMessage("");
  };

  const replierResponse = (title) => {
    setQueryResponseTitle(title);
    getActiveQueryResponseMessageList();
    setToggle2();
    setToggle5();
  };

  const closeQueryResponseModal = () => {
    setToggle2();
    setToggle5();
    setGenerateQueryType("");
    setQueryMessage("");
  };

  let studyStatus = JSON.parse(localStorage.getItem("studyStatus"));
  useEffect(() => {
    getActiveQuerySubjectList();
  }, [studyId]);
  return (
    <>
      <div>
        {(StudyMode === "SDV Live Mode" || StudyMode === "SDV QC Mode") && (
          <button
            className="m-1 hstack border-success border fontSize12 rounded-1 bg-blue text-white CP p-1 px-2"
            title="Unfreeze"
            style={{ fontSize: 10 }}
            onClick={(e) => openGenerateQueryModal(e)}
          >
            <BsFillTicketPerforatedFill
              style={{ fontSize: 15 }}
              className="pe-1"
            />
            Generate Ticket
          </button>
        )}
      </div>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "84%" }}
      >
        {loader ? (
          <SpinerDataLoader />
        ) : ticketList?.length > 0 ? (
          <Table id="resizable-table" striped className="m-0 custom-table">
            <thead className="thead-sticky">
              <tr className="align-baseline text-white lineheight">
                <th>Record ID</th>
                <th>Query ID</th>
                <th>Query Type</th>
                <th>Query Generated By</th>
                <th>Date & Time</th>
                <th>Query Status</th>
              </tr>
            </thead>

            <tbody className="border-top-0">
              {ticketList?.map(
                (
                  {
                    recordId,
                    ticketId,
                    queryType,
                    generatedBy,
                    createdAt,
                    status,
                    subjectId,
                  },
                  key
                ) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={key}
                    onClick={(e) => {
                      if (
                        studyPermissionData.replyLiveQuery ||
                        studyPermissionData.replyQCQuery ||
                        studyPermissionData?.siteIdForSDVLive?.length > 0 ||
                        studyPermissionData?.siteIdForSDVQC?.length > 0
                      ) {
                        if (queryType === "manual") {
                          openSingleViewQueryModal(
                            ticketId,
                            queryType,
                            status,
                            subjectId
                          );
                        }
                      }
                      if (queryType === "auto") {
                        getDataEntryFormFieldValidation(
                          recordId,
                          ticketId,
                          queryType,
                          generatedBy,
                          createdAt,
                          status
                        );
                      }
                    }}
                  >
                    <td>{recordId}</td>
                    <td>{ticketId}</td>
                    <td>
                      {queryType === "manual"
                        ? "Manual Query"
                        : queryType === "auto"
                        ? "Auto Query"
                        : ""}
                    </td>
                    <td>{generatedBy}</td>
                    <td>
                      <Moment format="DD MMM YYYY | HH:mm" withTitle>
                        {createdAt}
                      </Moment>
                    </td>
                    <td
                      className={
                        status === "closed"
                          ? "text-success textCapitialize"
                          : status === "open"
                          ? "text-danger textCapitialize"
                          : status === "cancel"
                          ? "text-dark textCapitialize"
                          : ""
                      }
                    >
                      {status}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        ) : (
          <NoDataFoundTable MSG="Query Not Found!" />
        )}
      </SplitPane>

      <Modal
        size="lg"
        show={!toggle2}
        className={!toggle2 ? "" : "d-none"}
        onHide={() => setToggle2()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Query Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <Row>
            <Col md={4} className="border__right_query p-0">
              <div className="p-2 m-2 border rounded-1 shadow-sm bg-light">
                <p className="mb-3 fontSize12">
                  <b>Query ID:</b> {queryId}
                </p>
                <p className="mb-3 fontSize12">
                  <b>Query Type:</b> {queryType}
                </p>
                <p className="mb-3 fontSize12">
                  <b>Site ID:</b> {siteName}
                </p>
                <p className="mb-3 fontSize12">
                  <b>Subject ID:</b> {subjectName}
                </p>
                <p className="mb-3 fontSize12">
                  <b>Visit ID:</b> {visitName}
                </p>
                <p className="mb-3 fontSize12">
                  <b>Form Name:</b> {formName}
                </p>
                <p className="mb-3 fontSize12">
                  <b>Form Field:</b> {formFieldName}
                </p>
                <p className="mb-3 fontSize12">
                  <b>Field Value:</b> {formFieldValue}
                </p>
              </div>
            </Col>
            <Col md={8} className="p-0">
              <div className="scroll__site_subject">
                {queries?.map((item, index) => (
                  <div key={index} className="p-2">
                    <div className="border rounded-1">
                      <div className="hstack justify-content-between border-bottom px-2 bg-light">
                        <p className="m-0 hstack gap-2 p-1">
                          <span className="text-danger" title="Responce Type">
                            {item.query === "Query Cancelled" ||
                            item.query === "Verified and Closed"
                              ? "Query Status"
                              : item.commentor
                              ? "Query"
                              : "Response"}
                          </span>
                          <div className="vr"></div>
                          <span
                            title="Reply By"
                            className={`textCapitialize ${
                              status === "open"
                                ? "text-danger"
                                : status === "cancel"
                                ? "text-dark"
                                : "text-success"
                            }`}
                          >
                            {item.commentor ? item.commentor : item.replier}
                          </span>
                          <div className="vr"></div>
                          <span title="Reply Date, Time">
                            <Moment format="DD MMM YYYY, HH:mm:ss">
                              {item.createdAt}
                            </Moment>
                          </span>
                        </p>
                        {!studyStatus &&
                        (StudyMode === "SDV Live Mode" ||
                          StudyMode === "SDV QC Mode" ||
                          StudyMode === "Live Mode" ||
                          StudyMode === "QC Mode") ? (
                          queryStatus === "open" ? (
                            <div className="hstack gap-2">
                              {item.commentor ? (
                                <>
                                  {StudyMode === "SDV Live Mode" ||
                                  StudyMode === "SDV QC Mode" ? (
                                    <BiSolidCommentEdit
                                      onClick={(e) =>
                                        updateCommentQueryModal(
                                          e,
                                          item.queryId,
                                          "commentor",
                                          item.query
                                        )
                                      }
                                      className="clients CP"
                                      title="Edit Query"
                                    />
                                  ) : StudyMode === "Live Mode" ||
                                    StudyMode === "QC Mode" ? (
                                    <BiSolidCommentDetail
                                      onClick={
                                        item.commentor
                                          ? () =>
                                              commentorResponse(
                                                "Query Response",
                                                item.subjectId
                                              )
                                          : () =>
                                              replierResponse(
                                                "Query Response",
                                                item.subjectId
                                              )
                                      }
                                      className="clients CP"
                                      title="Provide Response"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : StudyMode === "Live Mode" ||
                                StudyMode === "QC Mode" ? (
                                <BiSolidCommentEdit
                                  onClick={(e) =>
                                    updateCommentQueryModal(
                                      e,
                                      item.queryId,
                                      "replier",
                                      item.query
                                    )
                                  }
                                  className="clients CP"
                                  title="Edit Response"
                                />
                              ) : (
                                ""
                              )}
                              {(StudyMode === "SDV Live Mode" ||
                                StudyMode === "SDV QC Mode") &&
                                (item.commentor ? (
                                  ""
                                ) : (
                                  <BiSolidCommentX
                                    onClick={() =>
                                      replierResponse("Reject Response")
                                    }
                                    className="text-danger CP"
                                    title="Reject Response"
                                  />
                                ))}
                              {(StudyMode === "SDV Live Mode" ||
                                StudyMode === "SDV QC Mode") &&
                                (item.commentor ? (
                                  <BiSolidCommentX
                                    onClick={(e) =>
                                      updateQueryStatus(e, "cancel")
                                    }
                                    className="text-danger CP"
                                    title="Cancel Query"
                                  />
                                ) : (
                                  <BiSolidCommentCheck
                                    onClick={(e) =>
                                      updateQueryStatus(e, "closed")
                                    }
                                    className="text-success CP"
                                    title="Close Query"
                                  />
                                ))}
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <p className="m-0 fontSize12 p-2">{item.query}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Offcanvas
        size="md"
        className={!toggle7 ? "" : "d-none"}
        show={!toggle7}
        onHide={setToggle7}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Validation Error</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <Table striped className="m-0 custom-table">
            <thead className="thead-sticky">
              <tr>
                <th style={{ width: 100 }}>Attribute</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {visitFormType === "log" && (
                <tr>
                  <td>Record ID</td>
                  <td>{validationData?.recordId}</td>
                </tr>
              )}
              <tr>
                <td>Validation Status</td>
                <td
                  className={
                    validationData?.statusCode === 200
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  {validationData?.statusCode === 200 ? "Success" : "Error"}
                </td>
              </tr>
              <tr>
                <td>Message</td>
                <td
                  style={{
                    whiteSpace: "pre-wrap",
                    overflowY: "auto",
                    maxHeight: "150px",
                    padding: "5px",
                  }}
                  title={validationData?.message}
                >
                  {validationData?.message}
                </td>
              </tr>
              <tr>
                <td>Value</td>
                <td>{validationData?.fieldValue}</td>
              </tr>
              <tr>
                <td>Created Date</td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm" withTitle>
                    {validationData?.createdAt}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td>Record ID</td>
                <td>{validationData?.recordId}</td>
              </tr>
              <tr>
                <td>Query ID</td>
                <td>{queryId}</td>
              </tr>
              <tr>
                <td>Query Type</td>
                <td>{queryType}</td>
              </tr>
              <tr>
                <td>Query Generated By</td>
                <td>{generatedBy}</td>
              </tr>
              <tr>
                <td>Created At</td>
                <td>
                  {" "}
                  <Moment format="DD MMM YYYY | HH:mm" withTitle>
                    {createdBy}
                  </Moment>
                </td>
              </tr>
              <tr>
                <td>Query Status</td>
                <td>
                  <span
                    className={`text-capitalize ${
                      status === "open" ? "text-danger" : "text-success"
                    }`}
                  >
                    {status}
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        size="md"
        className={!toggle3 ? "" : "d-none"}
        show={!toggle3}
        onHide={closeGenerateQueryModal}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Generate Query</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="p-3 overflow-auto"
            style={{ height: "calc(100vh - 89px)" }}
          >
            <Row className="mb-2">
              <Form.Group as={Col} md={12} className="mb-2">
                <Form.Label className="mb-1">
                  <b>Query Subject</b> <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  className="rounded-1"
                  onChange={(e) => {
                    setSubject(e.target.value);
                    setSubjectType(e.target[e.target.selectedIndex].id);
                    getActiveQueryMessageList(
                      e.target[e.target.selectedIndex].title
                    );
                  }}
                >
                  <option value="">Select Subject</option>
                  <option id="customSubject" value="">
                    Custom Subject
                  </option>
                  {subjectList?.map((item, index) => (
                    <option
                      id="dynamicSubject"
                      key={index}
                      value={item?.querySubject}
                      title={item?._id}
                    >
                      {item?.querySubject}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              {subjectType === "dynamicSubject" ? (
                <Form.Group as={Col} md={12} className="mb-2">
                  <Form.Label className="mb-1">
                    <b>Query Message</b> <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    className="rounded-1"
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setMessageType(e.target[e.target.selectedIndex].id);
                    }}
                  >
                    <option value="">Select Message</option>
                    <option id="customMessage" value="">
                      Custom Message
                    </option>
                    {messageList?.map((item, index) => (
                      <option
                        id="dynamicMessage"
                        key={index}
                        value={item?.queryMessage}
                      >
                        {item?.queryMessage}
                      </option>
                    ))}
                  </Form.Select>
                  {messageType === "customMessage" && (
                    <Form.Group as={Col} md={12} className="mt-2">
                      <Form.Label className="mb-1">
                        <b>Custom Query Message</b>{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        className="rounded-1"
                        placeholder="Enter Custom Query Message"
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                      />
                    </Form.Group>
                  )}
                </Form.Group>
              ) : (
                subjectType === "customSubject" && (
                  <>
                    <Form.Group as={Col} md={12} className="mb-2">
                      <Form.Label className="mb-1">
                        <b>Custom Query Subject</b>{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="rounded-1"
                        placeholder="Enter Custom Query Subject"
                        onChange={(e) => setSubject(e.target.value)}
                        value={subject}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={12} className="mb-2">
                      <Form.Label className="mb-1">
                        <b>Custom Query Message</b>{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        className="rounded-1"
                        placeholder="Enter Custom Query Message"
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                      />
                    </Form.Group>
                  </>
                )
              )}
            </Row>
          </div>
          <div className="p-2 bg-light shadow-sm hstack justify-content-end gap-2">
            <button
              onClick={() => closeGenerateQueryModal()}
              className="border border-danger btn btn-outline-danger fontSize12 rounded-1 p-1 px-2"
            >
              Cancel
            </button>
            <button
              disabled={!subject || !message || loader}
              onClick={() => createQuery()}
              className="border rounded-1 p-1 px-2 updateProfile"
            >
              {loader && (
                <Spinner
                  className="me-1"
                  style={{ width: 15, height: 15 }}
                  animation="border"
                />
              )}
              Generate
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        size="md"
        className={!toggle4 ? "" : "d-none"}
        show={!toggle4}
        onHide={closeQueryCommenterResponseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{queryResponseTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generateQueryType === "custom" ? (
            <Form.Control
              as="textarea"
              className="rounded-1"
              placeholder="Enter Custom Query"
              onChange={(e) => setQueryMessage(e.target.value)}
              value={queryMessage}
            />
          ) : (
            <Form.Select
              onChange={(e) => {
                setQueryMessage(e.target.value);
                generateQueryMessageModal(e.target.value);
              }}
             className="rounded-1"
            >
              <option>Select Query Response Message</option>
              {queryResponseMessageList?.map((item, index) => (
                <option key={index} value={item.queryResponseMessage}>
                  {item.queryResponseMessage}
                </option>
              ))}
              <option value={"custom"}>Custom Query Response Message</option>
            </Form.Select>
          )}
        </Modal.Body>
        <Modal.Footer className="m-0 p-1 px-3 bg-light">
          <button
            onClick={() => dataEntryQueryComment()}
            className="submitbutton"
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        className={!toggle5 ? "" : "d-none"}
        show={!toggle5}
        onHide={closeQueryResponseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{queryResponseTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generateQueryType === "custom" ? (
            <Form.Control
              as="textarea"
              className="rounded-1"
              placeholder="Enter Custom Query"
              onChange={(e) => setQueryMessage(e.target.value)}
              value={queryMessage}
            />
          ) : (
            <Form.Select
              onChange={(e) => {
                setQueryMessage(e.target.value);
                generateQueryMessageModal(e.target.value);
              }}
               className="rounded-1"
            >
              <option>Select Query Response Message</option>
              {queryResponseMessageList?.map((item, index) => (
                <option key={index} value={item.queryResponseMessage}>
                  {item.queryResponseMessage}
                </option>
              ))}
              <option value={"custom"}>Custom Query Response Message</option>
            </Form.Select>
          )}
        </Modal.Body>
        <Modal.Footer className="m-0 p-1 px-3 bg-light">
          <button
            onClick={() => dataEntryQueryReply()}
            className="submitbutton"
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        className={!toggle6 ? "" : "d-none"}
        show={!toggle6}
        onHide={updateCommentQueryModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit {querySendingType === "replier" ? "Response" : "Query"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            className="rounded-1"
            placeholder="Enter Custom Query"
            onChange={(e) => setQueryMessage(e.target.value)}
            value={queryMessage}
          />
        </Modal.Body>
        <Modal.Footer className="m-0 p-1 px-3 bg-light">
          <button onClick={() => updateCommentQuery()} className="submitbutton">
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QueryTable;
