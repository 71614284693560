import React, { useState } from "react";
import AssignUser from "./ElearningProperties/AssignUser";
import EActivity from "./ElearningProperties/EActivity";
import ELearningActivity from "./ElearningProperties/ELearningActivity";

const ELearningProperties = ({ setIsSectionVisible, trainingId, viewTypeData }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const [activeTabs, setActiveTabs] = useState(viewTypeData === "ctms" ? "assign User" : "learning Activity");

  let propertiesData = viewTypeData === "ctms" ? ["assign User", "learning Activity", "activity"] : ["learning Activity", "activity"]
  return (
    <>
      <div
        className={`pt-1 bg-light border-bottom ${isFullScreen ? "full-screen-component" : ""
          }`}
      >
        <div className="hstack justify-content-between">
          <div className="hstack me-1 gap-1">
            {propertiesData?.map((tab) => (
              <button
                key={tab}
                className={`buttonForTabs ${activeTabs === tab ? "activee" : ""
                  }`}
                onClick={() => setActiveTabs(tab)}
                title={tab.charAt(0).toUpperCase() + tab.slice(1)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>
          <div className="d-flex gap-1">
            <div className="toolbar">
              <i
                className="fa-solid fa-angle-down"
                onClick={() => setIsSectionVisible(false)}
              ></i>
            </div>
            <div className="toolbar">
              <i
                className={`fa-solid me-2 ${!isFullScreen ? "fa-maximize" : "fa-minimize"
                  }`}
                title={!isFullScreen ? "Full Mode" : "Original Mode"}
                onClick={toggleFullScreen}
              ></i>
            </div>
          </div>
        </div>
        {activeTabs === "assign User" ? (
          <AssignUser trainingId={trainingId} />
        ) : activeTabs === "learning Activity" ? (
          <ELearningActivity />
        ) : activeTabs === "activity" ? (
          <EActivity />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ELearningProperties;
