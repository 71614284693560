/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { studyOnboardingService } from "../../../../../../Services/studyServices/studySubjectsServices";
import UseToggle from "../../../../../../Hooks/UseToggle";
import { UseSignature } from "../../UseAppendInput";
import { useParams } from "react-router";
import AddUpdateSiteSubjectRows from "./ActionFiles/AddUpdateSiteSubjectRows";
import AddUpdateSiteSubjectColumn from "./ActionFiles/AddUpdateSiteSubjectColumn";
import SubjectMetadata from "../SubjectMetadata/SubjectMetadata";
import SiteSubjectFilter from "./ActionFiles/SiteSubjectFilter";
import TableProperty from "./ActionFiles/TableProperty";
import SubjectHeaderMenu from "./SubjectHeaderMenu";
import SubjectTabProperties from "./Properties/SubjectTabProperties";
import GenerateStudyQuery from "./ActionFiles/GenerateStudyQuery";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../../NoDataFoundTable/NoDataFoundTable";
import SplitPane from "react-split-pane";
import SiteSubjectSignature from "./ActionFiles/SiteSubjectSignature";
import StudySiteSubjectTableData from "./StudySiteSubjectTableData";

const StudyIDStudyRandomized = ({
  subjectManage,
  findTableAndRegularViewData,
  tableRef,
  onMouseDown,
  handleAddNewTab,
  studySubjectState,
  breadcrumbData,
  selectedStudySiteId,
  siteIdData,
  viewTypeData,
  subjectStudyUniqueId,
}) => {
  const { studyId } = useParams();

  const {
    toggle,
    setToggle,
    toggle2,
    setToggle2,
    toggle4,
    setToggle4,
    toggle6,
    setToggle6,
    loader,
    setLoader,
  } = UseToggle();

  const [id, setId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [subjectGender, setSubjectGender] = useState("");

  const [studyDynamicColumn, setStudyDynamicColumn] = useState([]);
  const [studyDynamicRow, setStudyDynamicRow] = useState([]);
  const [updateStudyDynamicRow, setUpdateStudyDynamicRow] = useState([]);
  const [metedataComment, setMetedataComment] = useState([]);
  const [studyDynamicColumnID, setStudyDynamicColumnID] = useState([]);

  const [studySubjectHistoryLists, setStudySubjectHistoryLists] = useState([]);
  const [studyUniqueId, setStudyUniqueId] = useState("");
  const [reviewHistoryLists, setReviewHistoryLists] = useState([]);
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [loadSpan, setLoadSpan] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);
  const [statusCode, setStatusCode] = useState(0);
  const [customMessage, setCustomMessage] = useState("");
  const [updatedType, setUpdatedType] = useState();
  const [subjectUniqueId, setSubjectUniqueId] = useState("");
  const [selectedStudySubjectId, setSelectedStudySubjectId] = useState("");

  const getStudyRightSiteData = (item) => {
    setSubjectUniqueId(item.studyUniqueId);
    setSelectedStudySubjectId(item?.subjectIdLabel);
  };

  const limit = 100;

  const [formValues, setFormValues] = useState([]);

  const handleChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...formValues];
    newValues[i][name] = value;

    setFormValues(newValues);
  };

  const addFormFields = () => {
    setFormValues([...formValues, { name: "", columnValue: "", types: "" }]);
  };

  const removeFormFields = (e, i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);

    setFormValues(newFormValues);
  };

  const onChangeInput = (e, i) => {
    const { name, value } = e.target;

    let items1 = [...studyDynamicColumn];
    items1[i][name] = value;

    setStudyDynamicColumn(items1);
  };

  const { signForm, onValueChange } = UseSignature();

  const setIdReviewRowTable = (studyUniqueId) => {
    setToggle4();
    setStudyUniqueId(studyUniqueId);
  };

  const seIdGenerateQuery = (studyUniqueId) => {
    setToggle2();
    setStudyUniqueId(studyUniqueId);
  };

  const setIdUpdateRowTable = (
    id,
    studyUniqueId,
    dynamicRowData,
    subjectId
  ) => {
    setId(id);
    setStudyUniqueId(studyUniqueId);
    setUpdateStudyDynamicRow(Object.values(dynamicRowData));
    setSubjectId(subjectId);
    setUpdatedType("editRow");
  };

  const studyReview = async (e) => {
    e.preventDefault();
    let datas = {};
    datas.study_id = studyId;
    datas.studyUniqueId = studyUniqueId;
    datas.userId = signForm?.userName;
    datas.password = signForm?.password;
    datas.review = signForm?.comment;
    datas.status = signForm?.selectedOption;
    studyOnboardingService.reviewStudySubject(datas);
    getStudySubjectRowData();
  };

  const getStudySubjectReviewData = async (id) => {
    let data = await studyOnboardingService.getStudySubjectReviewData(id);
    let initialData = data?.data;
    let finalData = initialData?.sort()?.reverse();
    setReviewHistoryLists(finalData);
  };

  const getStudySubjectColumnData = async () => {
    setLoading(true);
    let data = await studyOnboardingService.getStudySubjectColumnData(studyId);
    let initialData = data?.data === undefined ? "" : data?.data;
    if (initialData.length > 0) {
      let finalData = initialData?.sort()?.reverse();
      setStudyDynamicColumn(Object.values(finalData?.[0].column));
      setUpdateStudyDynamicColumn(Object.values(finalData?.[0].column) || []);
      setStudyDynamicColumnID(finalData?.[0]._id);
    } else {
      setStudyDynamicColumn("");
      setStudyDynamicColumnID("");
    }
    setLoading(false);
  };
  const StudyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const getStudySubjectRowData = async (page) => {
    let currentPage = page ? page : 1;
    let data = await studyOnboardingService.getStudySubjectRowData(
      studyId,
      StudyMode,
      studyDynamicColumnID,
      studySubjectState,
      currentPage,
      limit
    );
    let initialData = Object.values(data.data === undefined ? "" : data?.data);
    setStudyDynamicRow(initialData);
  };

  useEffect(() => {
    getStudySubjectColumnData();
    getStudySubjectRowData();
    setIsSectionVisible(false);
  }, [studyDynamicColumnID, studySubjectState]);

  const cleanForAddNewSiteData = () => {
    setStudyUniqueId("");
    getStudySubjectColumnData();
    setSubjectId("");
    setSubjectGender("");
    setUpdatedType("addRow");
  };

  const createStudySubjectColumn = async (e) => {
    e.preventDefault();
    setLoadSpan(true);
    let datas = {
      studyID: studyId,
      dynamicColumnData: formValues,
      studyMode: StudyMode,
      siteUniqueId: studySubjectState,
    };
    let data = await studyOnboardingService.createStudySubjectColumn(datas);
    if (data?.statusCode === 200) {
      getStudySubjectColumnData();
      getTablePropertyData();
      setFormValues([]);
      setLoadSpan(false);
    } else {
      setLoadSpan(false);
    }
    setStatusCode(data?.statusCode);
    setCustomMessage(data?.customMessage);
  };

  const updateStudySubjectColumn = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Study Subject Column!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadSpan(true);
        if (updateStudyDynamicColumn.length === 0) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "At least one value is required to update!",
          });
          setLoadSpan(false);
          return;
        }
        let datas = {};
        datas.studyID = studyId;
        datas.dynamicColumnData = updateStudyDynamicColumn;
        let data = await studyOnboardingService.updateStudySubjectColumn(
          studyDynamicColumnID,
          datas
        );
        if (data.statusCode === 200) {
          getStudySubjectRowData();
          getTablePropertyData();
          getStudySubjectColumnData();
          setFormValues([]);
          setLoadSpan(false);
        } else {
          setLoadSpan(false);
        }
        setStatusCode(data?.statusCode);
        setCustomMessage(data?.customMessage);
      }
    });
  };

  const AddStudySubjectRow = async (e) => {
    e.preventDefault();
    setLoadSpan(true);
    setLoading(true);
    let datas = {
      studyID: studyId,
      subjectId: subjectId,
      subjectGender: subjectGender,
      columnId: studyDynamicColumnID,
      dynamicColumnData: studyDynamicColumn,
      studyMode: StudyMode,
      siteUniqueId: studySubjectState,
    };
    try {
      await studyOnboardingService.AddStudySubjectRow(datas);
      setStatusCode(200);
      setCustomMessage("Study subject row added successfully.");
      cleanForAddNewSiteData();
      getStudySubjectRowData();
    } catch (error) {
      setStatusCode(error.response?.status || 500);
      setCustomMessage(
        error.response?.data?.message ||
          "An error occurred while adding the study subject row."
      );
    } finally {
      setLoading(false);
      setLoadSpan(false);
    }
  };

  const UpdateStudySubjectRow = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Study Subject Row!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadSpan(true);
        setLoading(true);
        let datas = {
          studyID: studyId,
          subjectIdLabel: subjectId,
          subjectGender: subjectGender,
          dynamicColumnData: updateStudyDynamicRow,
          studyUniqueId: studyUniqueId,
          history: metedataComment,
        };
        try {
          await studyOnboardingService.UpdateStudySubjectRow(id, datas);
          setStatusCode(200);
          setCustomMessage("Study subject row updated successfully.");
          cleanForAddNewSiteData();
          await getStudySubjectRowData();
        } catch (error) {
          setStatusCode(error.response?.status || 500);
          setCustomMessage(
            error.response?.data?.message ||
              "An error occurred while updating the study subject row."
          );
        } finally {
          setLoadSpan(false);
          setLoadSpan(false);
        }
      }
    });
  };

  const UpdateStudySubjectRowStatus = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Study Subject Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        let datas = {};
        datas.studyID = studyId;
        datas.mode = StudyMode;
        await studyOnboardingService.UpdateStudySubjectRowStatus(id, datas);
        await getStudySubjectRowData();
      }
    });
  };

  const deleteStudySubjectRowData = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Study Subject!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        await studyOnboardingService.deleteStudySubjectRowData(id);
        await getStudySubjectRowData();
      }
    });
  };

  setTimeout(() => {
    setStatusCode(0);
    setCustomMessage("");
  }, 5000);

  // UPDATE COLUMN DATA ONCHANGE..........................................

  const [updateStudyDynamicColumn, setUpdateStudyDynamicColumn] = useState([]);

  const addUpdateOnChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...updateStudyDynamicColumn];
    newValues[i][name] = value;

    setUpdateStudyDynamicColumn(newValues);
  };

  const removeAddUpdateFields = (e, i) => {
    let newFormValues = [...updateStudyDynamicColumn];
    newFormValues.splice(i, 1);
    setUpdateStudyDynamicColumn(newFormValues);

    let originalColumnLength = studyDynamicColumn?.length;
    let finalIndex = i - originalColumnLength;
    if (finalIndex >= 0) {
      let newAddFormValues = [...formValues];
      newAddFormValues.splice(finalIndex, 1);
      setFormValues(newAddFormValues);
    }
  };

  // UPDATE ROW DATA ONCHANGE ..........................................

  const UpdateRowOnChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...updateStudyDynamicRow];
    newValues[i][name] = value;

    setUpdateStudyDynamicRow(newValues);
  };

  const UpdateHistoryOnChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...updateStudyDynamicRow];
    newValues[i][name] = value;

    setMetedataComment(newValues);
  };

  const permission = JSON.parse(localStorage.getItem("permission"));

  const validatorPermission = permission === "validator";

  const getStudySiteHistory = (id) => {
    getStudySubjectHistoryByID(id);
  };

  const getStudySubjectHistoryByID = async (e) => {
    let data = await studyOnboardingService.getStudySubjectHistoryByID(e);
    let initialData = data?.data;
    let finalData = initialData?.sort()?.reverse();
    setStudySubjectHistoryLists(finalData);
  };

  let allArray = !viewTypeData
    ? ["Subject Id", "Gender", "Status", "Query Status", "Query Date | Time"]
    : ["Subject Id", "Gender"];

  const [selectedNames, setSelectedNames] = useState([]);

  const getTablePropertyData = async () => {
    let data = await studyOnboardingService.getTablePropertyData(
      "siteSubject",
      studyDynamicColumnID,
      studyId
    );
    let columnProperty = data?.data?.columnData;
    let finalColumnProperty =
      columnProperty === undefined ? allArray : columnProperty;
    setSelectedNames(finalColumnProperty);
  };

  useEffect(() => {
    getTablePropertyData();
    if (!viewTypeData) {
      findTableAndRegularViewData("siteSubject");
    }
    setUpdateStudyDynamicColumn([...studyDynamicColumn, ...formValues] || []);
  }, [studyDynamicColumnID, formValues]);

  const addTableAndRegularView = async (e, viewType) => {
    let datas = {};
    datas.studyId = studyId;
    datas.category = "siteSubject";
    datas.StudyMode = StudyMode;
    datas.viewType = viewType;
    let data = await studyOnboardingService.addTableAndRegularView(datas);
    if (data.statusCode) {
      findTableAndRegularViewData("siteSubject");
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = studyDynamicRow?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(studyDynamicRow?.length / itemsPerPage);
  return (
    <>
      {!(subjectManage === "subjectManage") && (
        <SubjectHeaderMenu
          handleAddNewTab={handleAddNewTab}
          breadcrumbData={breadcrumbData}
          studyDynamicColumn={studyDynamicColumn}
          cleanForAddNewSiteData={cleanForAddNewSiteData}
          setUpdatedType={setUpdatedType}
          tableProperty={setToggle6}
          filterData={setToggle}
          studyDynamicRow={studyDynamicRow}
          updatedType={updatedType}
          addTableAndRegularView={addTableAndRegularView}
          filter={filter}
          setFilter={setFilter}
          getStudySubjectRowData={getStudySubjectRowData}
        />
      )}
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: "calc(100vh - 155px)",
          }}
          className="position-relative"
          defaultSize={!viewTypeData && isSectionVisible ? "40%" : "100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={updatedType ? "20%" : subjectManage === "subjectManage" ? "40%" : "100%"}
          >
            {(StudyMode === "DBP MODE" ||
              StudyMode === "Live Mode" ||
              StudyMode === "QC Mode") &&
              (subjectManage === "subjectManage" ? (
                <AddUpdateSiteSubjectColumn
                  formValues={formValues}
                  addHandleChange={handleChange}
                  updateHandleChange={addUpdateOnChange}
                  addFormFields={addFormFields}
                  removeFormFields={removeFormFields}
                  handleSubmit0={updateStudySubjectColumn}
                  handleSubmit={createStudySubjectColumn}
                  updateStudyDynamicColumn={updateStudyDynamicColumn}
                  studyDynamicColumn={studyDynamicColumn}
                  removeAddUpdateFields={removeAddUpdateFields}
                  loadSpan={loadSpan}
                  statusCode={statusCode}
                  customMessage={customMessage}
                  viewTypeData={viewTypeData}
                />
              ) : (
                (updatedType === "addRow" || updatedType === "editRow") && (
                  <AddUpdateSiteSubjectRows
                    updateHandleChange={UpdateRowOnChange}
                    addHandleChange={onChangeInput}
                    subjectId={subjectId}
                    setSubjectId={setSubjectId}
                    subjectGender={subjectGender}
                    setSubjectGender={setSubjectGender}
                    handleSubmit1={AddStudySubjectRow}
                    handleSubmit2={UpdateStudySubjectRow}
                    studyUniqueId={studyUniqueId}
                    studyDynamicColumnData={updateStudyDynamicRow}
                    studyAddDynamicColumnData={studyDynamicColumn}
                    cleanForAddNewSiteData={cleanForAddNewSiteData}
                    UpdateHistoryOnChange={UpdateHistoryOnChange}
                    loadSpan={loadSpan}
                    statusCode={statusCode}
                    customMessage={customMessage}
                  />
                )
              ))}
            {subjectManage === "subjectManage" ? (
              loading ? (
                <SpinerDataLoader />
              ) : (
                <SubjectMetadata studyDynamicColumn={studyDynamicColumn} />
              )
            ) : loading ? (
              <SpinerDataLoader />
            ) : studyDynamicColumn?.length === 0 ? (
              <NoDataFoundTable MSG="Subject not found" />
            ) : (
              <StudySiteSubjectTableData
                handleAddNewTab={handleAddNewTab}
                studyDynamicColumn={studyDynamicColumn}
                hideColumn={selectedNames}
                studyDynamicRow={result}
                setIdUpdateRowTable={setIdUpdateRowTable}
                UpdateStudySubjectRowStatus={UpdateStudySubjectRowStatus}
                setIdReviewRowTable={setIdReviewRowTable}
                handleSubmit3={deleteStudySubjectRowData}
                getStudyRightSiteData={getStudyRightSiteData}
                getStudySiteHistory={getStudySiteHistory}
                setIsSectionVisible={setIsSectionVisible}
                tableRef={tableRef}
                onMouseDown={onMouseDown}
                totalData={studyDynamicRow}
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
                startIndex={startIndex}
                setUpdatedType={setUpdatedType}
                seIdGenerateQuery={seIdGenerateQuery}
                selectedStudySiteId={selectedStudySiteId}
                studySubjectState={studySubjectState}
                siteIdData={siteIdData}
                viewTypeData={viewTypeData}
              />
            )}
          </SplitPane>
          {!viewTypeData &&
            isSectionVisible &&
            studyDynamicColumn?.length > 0 && (
              <SubjectTabProperties
                subjectUniqueId={subjectUniqueId}
                handleAddNewTab={handleAddNewTab}
                tableRef={tableRef}
                onMouseDown={onMouseDown}
                setIsSectionVisible={setIsSectionVisible}
                getStudySubjectRowData={getStudySubjectRowData}
                selectedStudySubjectId={selectedStudySubjectId}
                selectedStudySiteId={selectedStudySiteId}
                studySubjectState={studySubjectState}
                siteIdData={siteIdData}
                subjectStudyUniqueId={subjectStudyUniqueId}
              />
            )}
        </SplitPane>
      </div>
      <TableProperty
        Show={!toggle6}
        Hide={setToggle6}
        allArray={allArray}
        selectedNames={selectedNames}
        setSelectedNames={setSelectedNames}
        studyDynamicColumn={studyDynamicColumn}
        studyId={studyId}
        studyDynamicColumnID={studyDynamicColumnID}
        getStudySubjectColumnData={getStudySubjectColumnData}
        getStudySubjectRowData={getStudySubjectRowData}
        Title={"Table Property"}
        viewTypeData={viewTypeData}
      />
      <SiteSubjectFilter
        Show={!toggle}
        Hide={setToggle}
        Title={"Filter"}
        filter={filter}
        setFilter={setFilter}
        setStudyDynamicRow={setStudyDynamicRow}
        getStudySubjectRowData={getStudySubjectRowData}
        studyDynamicColumnID={studyDynamicColumnID}
        studySubjectState={studySubjectState}
      />
      <GenerateStudyQuery
        Show={!toggle2}
        Hide={setToggle2}
        studyUniqueId={studyUniqueId}
        getStudySubjectRowData={getStudySubjectRowData}
        Title={"Generate Query"}
        Submit="Generate"
      />
      <SiteSubjectSignature
        Show={!toggle4}
        Hide={setToggle4}
        studyUniqueId={studyUniqueId}
        Title={"Signature Required"}
      />
    </>
  );
};

export default StudyIDStudyRandomized;
