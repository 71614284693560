import React, { useState } from "react";
import StudySiteAttributeTable from "./StudySiteAttributeTable";
import StudySiteAuditLogs from "./StudySiteAuditLogs";
import UpdatedFieldHistory from "./UpdatedFieldHistory";
import StudySiteQueryListComponent from "./QueryManagement/StudySiteQueryListComponent";
import SiteSubjectList from "./SiteSubjectList";
import SiteReviewHistory from "./SiteReviewHistory";
import StudySiteReport from "./QueryManagement/StudySiteReport";
const SiteTabProperties = ({
  siteUniqueId,
  subjectUniqueId,
  tableRef,
  onMouseDown,
  setIsSectionVisible,
  getStudySiteRowData,
  handleAddNewTab,
  selectedStudySiteId,
  studySubjectState,
  siteIdData,
  StudyMode,
  viewTypeData
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeTabs, setActiveTabs] = useState("properties");
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  let finalTabArray = viewTypeData === "edc" ?
    [
      "properties",
      "history",
      "subject",
      "report",
      "activities",
    ] : [
      "properties",
      "review History",
      "query",
    ]

  return (
    <>
      <div
        className={`pt-1 border-bottom bg-light ${isFullScreen ? "full-screen-component" : ""
          }`}
      >
        <div className="hstack justify-content-between">
          <div className="hstack me-1 gap-1">
            {finalTabArray?.map((tab) => (
              <button
                key={tab}
                className={`buttonForTabs ${activeTabs === tab ? "activee" : ""
                  }`}
                onClick={() => setActiveTabs(tab)}
                title={tab.charAt(0).toUpperCase() + tab.slice(1)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>
          <div className="d-flex gap-1">
            <div className="toolbar">
              <i
                className="fa-solid fa-angle-down"
                onClick={() => setIsSectionVisible(false)}
              >
              </i>
            </div>
            <div className="toolbar">
              <i
                className={`fa-solid me-2 ${!isFullScreen ? "fa-maximize" : "fa-minimize"
                  }`}
                title={!isFullScreen ? "Full Mode" : "Original Mode"}
                onClick={toggleFullScreen}
              ></i>
            </div>
          </div>
        </div>
        {activeTabs === "properties" ? (
          <StudySiteAttributeTable
            siteUniqueId={siteUniqueId}
            tableRef={tableRef}
            onMouseDown={onMouseDown}
          />
        ) : activeTabs === "history" ? (
          <UpdatedFieldHistory
            siteUniqueId={siteUniqueId}
            tableRef={tableRef}
            onMouseDown={onMouseDown}
          />
        ) : activeTabs === "review History" ? (
          <SiteReviewHistory tableRef={tableRef} onMouseDown={onMouseDown} studyUniqueId={siteUniqueId} />
        ) : activeTabs === "subject" ? (
          <SiteSubjectList
            subjectUniqueId={subjectUniqueId}
            handleAddNewTab={handleAddNewTab}
            studySubjectState={studySubjectState}
            selectedStudySiteId={selectedStudySiteId}
            siteIdData={siteIdData}
          />
        ) : activeTabs === "report" ? (
          <StudySiteReport />
        ) : activeTabs === "query" ? (
          <StudySiteQueryListComponent
            tableRef={tableRef}
            onMouseDown={onMouseDown}
            siteUniqueId={siteUniqueId}
            isFullScreen={isFullScreen}
            getStudySiteRowData={getStudySiteRowData}
          />
        ) : activeTabs === "activities" ? (
          <StudySiteAuditLogs
            tableRef={tableRef}
            onMouseDown={onMouseDown}
            siteUniqueId={siteUniqueId}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default SiteTabProperties;
