import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

const RandomizeSideData = ({ siteList }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [showRandomizedSite, setShowRandomizedSite] = useState(false);

    const handleCheckboxChange = (event) => {
        setShowOptions(event.target.checked);
    };

    const handleRandomizeClick = () => {
        setShowRandomizedSite(true);
    };

    return (
        <>
            <div className="p-2">
                <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                        Site
                    </Form.Label>
                    <Form.Select aria-label="Default select example">
                        <option>
                            Select Site List
                        </option>
                        {siteList?.map((site) => (
                            <option key={site._id} value={site.siteId}>
                                {site.siteIdLabel}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                        Subject Id
                    </Form.Label>
                    <Form.Control type="text" placeholder="Enter Subject Id" />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                        Gender
                    </Form.Label>
                    <Form.Select aria-label="Default select example">
                        <option>Select Gender</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                        <option value="3">Others</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                        Remarks
                    </Form.Label>
                    <Form.Control type="text" placeholder="Write Remarks Here" />
                </Form.Group>
                <div className="text-end mb-3 px-1">
                    <Button
                        className="updateProfile"
                        style={{ width: 100, fontSize: "13px", marginRight: "5px" }}
                        onClick={handleRandomizeClick}
                    >
                        Randomize
                    </Button>
                </div>
            </div>
        </>
    );
};

export default RandomizeSideData;
