import React, { useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa6";
import { Badge, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { userServices } from "../../../Services/userServices";
import { studyOnboardingService } from "../../../Services/studyServices/studyOnboarding";

const CtmsDashboardData = () => {
  const { studyId } = useParams();
  const [studyRole, setStudyRole] = useState(null);
  const [DBPPermission, setDBPPermission] = useState([]);
  const [DVPermission, setDVPermission] = useState([]);
  const [LivePermission, setLivePermission] = useState([]);
  const [QCPermission, setQCPermission] = useState([]);
  const [SDVLivePermission, setSDVLivePermission] = useState([]);
  const [SDVQCPermission, setSDVQCPermission] = useState([]);
  const [studyPermissionData, setStudyPermissionData] = useState([]);

  const [DBPSwitchCheck, setDBPSwitchCheck] = useState("");
  const [DVSwitchCheck, setDVSwitchCheck] = useState("");
  const [LiveSwitchCheck, setLiveSwitchCheck] = useState("");
  const [QCSwitchCheck, setQCSwitchCheck] = useState("");
  const [SDVLiveSwitchCheck, setSDVLiveSwitchCheck] = useState("");
  const [SDVQCSwitchCheck, setSDVQCSwitchCheck] = useState("");
  const [permissionId, setPermissionId] = useState("");

  const [ETMFDBPSwitchCheck, setETMFDBPSwitchCheck] = useState("");
  const [ETMFLiveSwitchCheck, setETMFLiveSwitchCheck] = useState("");
  const [ETMFQCSwitchCheck, setETMFQCSwitchCheck] = useState("");
  const [ETMFpermissionId, setETMFPermissionId] = useState("");

  const [IWRSDBPSwitchCheck, setIWRSDBPSwitchCheck] = useState("");
  const [IWRSLiveSwitchCheck, setIWRSLiveSwitchCheck] = useState("");
  const [IWRSQCSwitchCheck, setIWRSQCSwitchCheck] = useState("");
  const [IWRSpermissionId, setIWRSPermissionId] = useState("");

  const [studyPermissionId, setStudyPermissionId] = useState("");
  const [dataviewStudyLogin, setDataviewStudyLogin] = useState([]);
  const [studyManagement, setStudyManagement] = useState("");
  const [assignStudyPermission, setAssignStudyPermission] = useState("");
  const [assignETMFStudyPermission, setAssignETMFStudyPermission] = useState("");
  const [assignIWRSStudyPermission, setAssignIWRSStudyPermission] = useState("");

  const [DBPCheck, setDBPCheck] = useState("");
  const [DVCheck, setDVCheck] = useState("");
  const [LiveCheck, setLiveCheck] = useState("");
  const [QCCheck, setQCCheck] = useState("");
  const [SDVLiveCheck, setSDVLiveCheck] = useState("");
  const [SDVQCCheck, setSDVQCCheck] = useState("");
  const [studyStatus, setStudyStatus] = useState("");
  const [studyStatusData, setStudyStatusData] = useState("");

  const [ETMFStudyPermissionData, setETMFStudyPermissionData] = useState([]);
  const [ETMFStudyManagement, setETMFStudyManagement] = useState("");
  const [ETMFDBPPermission, setETMFDBPPermission] = useState([]);
  const [ETMFLivePermission, setETMFLivePermission] = useState([]);
  const [ETMFQCPermission, setETMFQCPermission] = useState([]);
  const [ETMFSDVLivePermission, setETMFSDVLivePermission] = useState([]);
  const [ETMFSDVQCPermission, setETMFSDVQCPermission] = useState([]);
  const [ETMFDBPCheck, setETMFDBPCheck] = useState("");
  const [ETMFLiveCheck, setETMFLiveCheck] = useState("");
  const [ETMFQCCheck, setETMFQCCheck] = useState("");
  const [ETMFSDVLiveCheck, setETMFSDVLiveCheck] = useState("");
  const [ETMFSDVQCCheck, setETMFSDVQCCheck] = useState("");

  const [IWRSStudyPermissionData, setIWRSStudyPermissionData] = useState([]);
  const [IWRSStudyManagement, setIWRSStudyManagement] = useState("");
  const [IWRSDBPPermission, setIWRSDBPPermission] = useState([]);
  const [IWRSLivePermission, setIWRSLivePermission] = useState([]);
  const [IWRSQCPermission, setIWRSQCPermission] = useState([]);
  const [IWRSSDVLivePermission, setIWRSSDVLivePermission] = useState([]);
  const [IWRSSDVQCPermission, setIWRSSDVQCPermission] = useState([]);
  const [IWRSDBPCheck, setIWRSDBPCheck] = useState("");
  const [IWRSLiveCheck, setIWRSLiveCheck] = useState("");
  const [IWRSQCCheck, setIWRSQCCheck] = useState("");
  const [IWRSSDVLiveCheck, setIWRSSDVLiveCheck] = useState("");
  const [IWRSSDVQCCheck, setIWRSSDVQCCheck] = useState("");

  const [projectID, setProjectID] = useState("");
  const [projectLabel, setProjectLabel] = useState("");


  const [studyLabel, setStudyLabel] = useState("");
  const getETMFUserPermission = async () => {
    let permission = await userServices.getETMFUserPermission(
      studyId,
      projectID
    );
    setETMFPermissionId(permission.permissionId)
    setStudyRole(permission.permissionRole);
    setETMFStudyPermissionData(permission?.permission);
    setETMFDBPPermission(permission?.DBPPermission);
    setETMFLivePermission(permission?.LivePermission);
    setETMFQCPermission(permission?.QCPermission);
    setETMFDBPSwitchCheck(permission?.DBPCheck);
    setETMFLiveSwitchCheck(permission?.LiveCheck);
    setETMFQCSwitchCheck(permission?.QCCheck);
  };

  const getStudyUserPermission = async () => {
    let permission = await userServices.getStudyUserPermission(
      studyId,
      projectID
    );

    setPermissionId(permission.permissionId)
    setStudyRole(permission.permissionRole);
    setStudyPermissionData(permission?.permission);
    setDBPPermission(permission?.DBPPermission);
    setDVPermission(permission?.DVPermission);
    setLivePermission(permission?.LivePermission);
    setQCPermission(permission?.QCPermission);
    setSDVLivePermission(permission?.SDVLivePermission);
    setSDVQCPermission(permission?.SDVQCPermission);
    setDBPSwitchCheck(permission?.DBPCheck);
    setDVSwitchCheck(permission?.DVCheck);
    setLiveSwitchCheck(permission?.LiveCheck);
    setQCSwitchCheck(permission?.QCCheck);
    setSDVLiveSwitchCheck(permission?.SDVLiveCheck);
    setSDVQCSwitchCheck(permission?.SDVQCCheck);
  };

  const getIWRSUserPermission = async () => {
    let permission = await userServices.getIWRSUserPermission(
      studyId,
      projectID
    );
    setIWRSPermissionId(permission.permissionId)
    setStudyRole(permission.permissionRole);
    setIWRSStudyPermissionData(permission?.permission);
    setIWRSDBPPermission(permission?.DBPPermission);
    setIWRSLivePermission(permission?.LivePermission);
    setIWRSQCPermission(permission?.QCPermission);
    setIWRSDBPSwitchCheck(permission?.DBPCheck);
    setIWRSLiveSwitchCheck(permission?.LiveCheck);
    setIWRSQCSwitchCheck(permission?.QCCheck);
  };

  const getProjectIdByStudyId = async () => {
    let projectId = await studyOnboardingService.getProjectIdByStudyId(
      studyId
    );
    setProjectID(projectId?.data?.project_id);
    setProjectLabel(projectId?.data?.projectLabel);
    setStudyLabel(projectId?.data?.studyLabel);
  };

  const viewSelectedItemWithSign = async () => {

    setStudyStatus(studyStatusData);
    setStudyStatusData("active");
    let permissionData = await userServices.getStudyUserPermission(
      studyId,
      projectID
    );
    let etmfPermissionData = await userServices.getETMFUserPermission(
      studyId,
      projectID
    );
    let iwrsPermissionData = await userServices.getIWRSUserPermission(
      studyId,
      projectID
    );

    setStudyPermissionId(
      permissionData?.permissionId ? permissionData?.permissionId : ""
    );
    setStudyPermissionData(permissionData?.permission);
    setStudyManagement(permissionData?.permissionRole);
    setDBPPermission(permissionData?.DBPPermission);
    setDVPermission(permissionData?.DVPermission);
    setLivePermission(permissionData?.LivePermission);
    setQCPermission(permissionData?.QCPermission);
    setSDVLivePermission(permissionData?.SDVLivePermission);
    setSDVQCPermission(permissionData?.SDVQCPermission);
    setDBPCheck(permissionData?.DBPCheck);
    setDVCheck(permissionData?.DVCheck);
    setLiveCheck(permissionData?.LiveCheck);
    setQCCheck(permissionData?.QCCheck);
    setSDVLiveCheck(permissionData?.SDVLiveCheck);
    setSDVQCCheck(permissionData?.SDVQCCheck);
    setDataviewStudyLogin(permissionData?.checkDataviewStudyLogin);

    setETMFStudyPermissionData(etmfPermissionData?.permission);
    setETMFStudyManagement(etmfPermissionData?.permissionRole);
    setETMFDBPPermission(etmfPermissionData?.DBPPermission);
    setETMFLivePermission(etmfPermissionData?.LivePermission);
    setETMFQCPermission(etmfPermissionData?.QCPermission);
    setETMFDBPCheck(etmfPermissionData?.DBPCheck);
    setETMFLiveCheck(etmfPermissionData?.LiveCheck);
    setETMFQCCheck(etmfPermissionData?.QCCheck);

    setIWRSStudyPermissionData(iwrsPermissionData?.permission);
    setIWRSStudyManagement(iwrsPermissionData?.permissionRole);
    setIWRSDBPPermission(iwrsPermissionData?.DBPPermission);
    setIWRSLivePermission(iwrsPermissionData?.LivePermission);
    setIWRSQCPermission(iwrsPermissionData?.QCPermission);
    setIWRSDBPCheck(iwrsPermissionData?.DBPCheck);
    setIWRSLiveCheck(iwrsPermissionData?.LiveCheck);
    setIWRSQCCheck(iwrsPermissionData?.QCCheck);
  };

  const previousRole = JSON.parse(localStorage.getItem("role"));
  const previousMode = JSON.parse(localStorage.getItem("StudyMode"));
  const ctmsPermissionData = JSON.parse(localStorage.getItem("ctmsPermissionData"));

  const loginStudyEDCPanel = async () => {
    localStorage.removeItem("studyTabs");
    localStorage.setItem("role", JSON.stringify("studyId"));
    localStorage.setItem("previousMode", JSON.stringify(previousMode));
    localStorage.setItem("studyStatus", JSON.stringify(studyStatus));
    localStorage.setItem("previousRole", JSON.stringify(previousRole));
    localStorage.setItem("studyStatusData", JSON.stringify(studyStatusData));
    localStorage.setItem(
      "StudyMode",
      JSON.stringify(
        assignStudyPermission === "DBP"
          ? "DBP MODE"
          : assignStudyPermission === "DV"
            ? "DV Mode"
            : assignStudyPermission === "LIVE"
              ? "Live Mode"
              : assignStudyPermission === "QC"
                ? "QC Mode"
                : assignStudyPermission === "SDVLIVE"
                  ? "SDV Live Mode"
                  : assignStudyPermission === "SDVQC"
                    ? "SDV QC Mode"
                    : "No Mode"
      )
    );

    window.location.href = `/studyId/${studyId}`;
    localStorage.setItem(
      "studyPermissionData",
      JSON.stringify(
        assignStudyPermission === "DBP"
          ? DBPPermission[0]
          : assignStudyPermission === "DV"
            ? DVPermission[0]
            : assignStudyPermission === "LIVE"
              ? LivePermission[0]
              : assignStudyPermission === "QC"
                ? QCPermission[0]
                : assignStudyPermission === "SDVLIVE"
                  ? SDVLivePermission[0]
                  : assignStudyPermission === "SDVQC"
                    ? SDVQCPermission[0]
                    : ""
      )
    );
    localStorage.setItem(
      "studyPermissionId",
      JSON.stringify(studyPermissionId)
    );
    localStorage.setItem("studyManagement", JSON.stringify(studyManagement));
  };

  const loginStudyETMFPanel = async () => {
    localStorage.removeItem("studyTabs");
    localStorage.setItem("role", JSON.stringify("etmf"));
    localStorage.setItem("previousMode", JSON.stringify(previousMode));
    localStorage.setItem("studyStatus", JSON.stringify(studyStatus));
    localStorage.setItem("previousRole", JSON.stringify(previousRole));
    localStorage.setItem("studyStatusData", JSON.stringify(studyStatusData));
    localStorage.setItem(
      "StudyMode",
      JSON.stringify(
        assignETMFStudyPermission === "DBP"
          ? "DBP MODE"
          : assignETMFStudyPermission === "LIVE"
            ? "Live Mode"
            : assignETMFStudyPermission === "QC"
              ? "QC Mode"
              : "No Mode"
      )
    );

    let etmfStudyData = await studyOnboardingService.etmfStudyDatabaseStatus(
      studyId
    );
    window.location.href = `/etmf/${studyId}`;
    localStorage.setItem(
      "etmfPermissionData",
      JSON.stringify(
        assignETMFStudyPermission === "DBP"
          ? ETMFDBPPermission[0]
          : assignETMFStudyPermission === "LIVE"
            ? ETMFLivePermission[0]
            : assignETMFStudyPermission === "QC"
              ? ETMFQCPermission[0]
              : "No Mode"
      )
    );
    localStorage.setItem(
      "etmfStudyManagement",
      JSON.stringify(ETMFStudyManagement)
    );
    localStorage.setItem(
      "etmfDatabaseStatus",
      JSON.stringify(etmfStudyData?.etmfDatabaseStatus)
    );
  };


  const loginStudyIWRSPanel = async () => {
    localStorage.removeItem("studyTabs");
    localStorage.setItem("role", JSON.stringify("iwrs"));
    localStorage.setItem("studyStatus", JSON.stringify(studyStatus));
    localStorage.setItem("previousRole", JSON.stringify(previousRole));
    localStorage.setItem("previousMode", JSON.stringify(previousMode));
    localStorage.setItem("studyStatusData", JSON.stringify(studyStatusData));
    localStorage.setItem(
      "StudyMode",
      JSON.stringify(
        assignIWRSStudyPermission === "DBP"
          ? "DBP MODE"
          : assignIWRSStudyPermission === "LIVE"
            ? "Live Mode"
            : assignIWRSStudyPermission === "QC"
              ? "QC Mode"
              : "No Mode"
      )
    );
    window.location.href = `/iwrs/${studyId}`;
    localStorage.setItem(
      "iwrsPermissionData",
      JSON.stringify(
        assignIWRSStudyPermission === "DBP"
          ? IWRSDBPPermission[0]
          : assignIWRSStudyPermission === "LIVE"
            ? IWRSLivePermission[0]
            : assignIWRSStudyPermission === "QC"
              ? IWRSQCPermission[0]
              : ""
      )
    );
    localStorage.setItem(
      "iwrsStudyManagement",
      JSON.stringify(IWRSStudyManagement)
    );
    // localStorage.setItem(
    //   "iwrsDatabaseStatus",
    //   JSON.stringify(iwrsStudyData?.iwrsDatabaseStatus)
    // );
  };

  useEffect(() => {
    getProjectIdByStudyId();
  }, [studyId]);

  useEffect(() => {
    getETMFUserPermission();
    getIWRSUserPermission();
    getStudyUserPermission();
    viewSelectedItemWithSign()
  }, [projectID]);
  return (
    <>
      <div
        className="mt-2 overflow-auto"
        style={{ height: "calc(100vh - 165px" }}
      >
        <Container fluid>
          <Row>
            <Col md={4} sm={12}>
              <Card className="mb-2 rounded-1 bg-light shadow-sm border">
                <Card.Body>
                  <div className="d-flex gap-3 mb-1">
                    <div className="study_icopn">
                      <FaUsers className="rounded-1 bg-white shadow-sm w-100" />
                      <Badge className="rounded-1 w-100 bg-success fs11">
                        Analytic
                      </Badge>
                    </div>
                    <div className="w-100">
                      <Form.Label className="mb-1 d-block fw-bold">
                        Galax EDC
                      </Form.Label>
                      <Form.Label className="mb-1 fs11 d-block">
                        <span className="fw-medium">ProjectID</span> : {projectLabel}
                      </Form.Label>
                      <Form.Label className="fs11 d-block">
                        <span className="fw-medium">StudyID</span> : {studyLabel}
                      </Form.Label>
                    </div>
                  </div>
                  <div className="hstack gap-2">
                    <Form.Select
                      className="w-100 py-1 px-2 border rounded-1"
                      style={{ fontSize: 11 }}
                      onChange={(e) => setAssignStudyPermission(e.target.value)}
                      disabled={!(ctmsPermissionData?.edcApplicationDBP || ctmsPermissionData?.edcApplicationDV || ctmsPermissionData?.edcApplication)}
                    >
                      <option>Select Environment</option>
                      <>
                        {DBPCheck || DBPSwitchCheck ? (
                          <option value={"DBP"}>DBP Mode</option>
                        ) : (
                          ""
                        )}
                        {DVCheck || DVSwitchCheck ? (
                          <option value={"DV"}>DV Mode</option>
                        ) : (
                          ""
                        )}
                        {LiveCheck || LiveSwitchCheck ? (
                          <option value={"LIVE"}>Live Mode</option>
                        ) : (
                          ""
                        )}
                        {SDVLiveCheck || SDVLiveSwitchCheck ?
                          <option value={"SDVLIVE"}>SDV Live Mode</option>
                          : ""}
                        {QCCheck || QCSwitchCheck ? (
                          <option value={"QC"}>QC Mode</option>
                        ) : (
                          ""
                        )}
                        {SDVQCCheck || SDVQCSwitchCheck ?
                          <option value={"SDVQC"}>SDV QC Mode</option>
                          : ""}
                      </>
                    </Form.Select>
                    <button
                      disabled={!assignStudyPermission || !(ctmsPermissionData?.edcApplicationDBP || ctmsPermissionData?.edcApplicationDV || ctmsPermissionData?.edcApplication)}
                      onClick={loginStudyEDCPanel}
                      className="updateProfile fs11 rounded-1 px-3 pb-1 pt-1">
                      Login
                    </button>
                  </div>
                </Card.Body>
              </Card>
              <Card className="mb-2 rounded-1 bg-light shadow-sm border">
                <Card.Body>
                  <div className="d-flex gap-3 mb-1">
                    <div className="study_icopn">
                      <FaUsers className="rounded-1 bg-white shadow-sm w-100" />
                      <Badge className="rounded-1 w-100 bg-success fs11">
                        Analytic
                      </Badge>
                    </div>
                    <div className="w-100">
                      <Form.Label className="mb-1 d-block fw-bold">
                        Galax ETMF
                      </Form.Label>
                      <Form.Label className="mb-1 fs11 d-block">
                        <span className="fw-medium">ProjectID</span> : {projectLabel}
                      </Form.Label>
                      <Form.Label className="fs11 d-block">
                        <span className="fw-medium">StudyID</span> : {studyLabel}
                      </Form.Label>
                    </div>
                  </div>
                  <div className="hstack gap-2">
                    <Form.Select
                      className="w-100 py-1 px-2 border rounded-1"
                      style={{ fontSize: 11 }}
                      onChange={(e) => setAssignETMFStudyPermission(e.target.value)}
                      disabled={!(ctmsPermissionData?.etmfApplicationDBP || ctmsPermissionData?.etmfApplicationDV || ctmsPermissionData?.etmfApplication)}
                    >
                      <option>Select Environment</option>
                      <>
                        {ETMFDBPCheck || DBPSwitchCheck ? (
                          <option value={"DBP"}>DBP Mode</option>
                        ) : (
                          ""
                        )}
                        {ETMFLiveCheck || LiveSwitchCheck ? (
                          <option value={"LIVE"}>Live Mode</option>
                        ) : (
                          ""
                        )}
                        {ETMFQCCheck || QCSwitchCheck ? (
                          <option value={"QC"}>QC Mode</option>
                        ) : (
                          ""
                        )}
                      </>
                    </Form.Select>
                    <button
                      disabled={!assignETMFStudyPermission || !(ctmsPermissionData?.etmfApplicationDBP || ctmsPermissionData?.etmfApplicationDV || ctmsPermissionData?.etmfApplication)}
                      onClick={loginStudyETMFPanel}
                      className="updateProfile fs11 rounded-1 px-3 pb-1 pt-1">
                      Login
                    </button>
                  </div>
                </Card.Body>
              </Card>
              <Card className="mb-2 rounded-1 bg-light shadow-sm border">
                <Card.Body>
                  <div className="d-flex gap-3 mb-1">
                    <div className="study_icopn">
                      <FaUsers className="rounded-1 bg-white shadow-sm w-100" />
                      <Badge className="rounded-1 w-100 bg-success fs11">
                        Analytic
                      </Badge>
                    </div>
                    <div className="w-100">
                      <Form.Label className="mb-1 d-block fw-bold">
                        Galax IWRS
                      </Form.Label>
                      <Form.Label className="mb-1 fs11 d-block">
                        <span className="fw-medium">ProjectID</span> : {projectLabel}
                      </Form.Label>
                      <Form.Label className="fs11 d-block">
                        <span className="fw-medium">StudyID</span> : {studyLabel}
                      </Form.Label>
                    </div>
                  </div>
                  <div className="hstack gap-2">
                    <Form.Select
                      className="w-100 py-1 px-2 border rounded-1"
                      style={{ fontSize: 11 }}
                      onChange={(e) => setAssignIWRSStudyPermission(e.target.value)}
                      disabled={!(ctmsPermissionData?.randomizerApplicationDBP || ctmsPermissionData?.randomizerApplicationDV || ctmsPermissionData?.randomizerApplication)}
                    >
                      <option>Select Environment</option>
                      <>
                        {IWRSDBPCheck || DBPSwitchCheck ? (
                          <option value={"DBP"}>DBP Mode</option>
                        ) : (
                          ""
                        )}
                        {IWRSLiveCheck || LiveSwitchCheck ? (
                          <option value={"LIVE"}>Live Mode</option>
                        ) : (
                          ""
                        )}
                        {IWRSQCCheck || QCSwitchCheck ? (
                          <option value={"QC"}>QC Mode</option>
                        ) : (
                          ""
                        )}
                      </>
                    </Form.Select>
                    <button
                      disabled={!assignIWRSStudyPermission || !(ctmsPermissionData?.randomizerApplicationDBP || ctmsPermissionData?.randomizerApplicationDV || ctmsPermissionData?.randomizerApplication)}
                      onClick={loginStudyIWRSPanel}
                      className="updateProfile fs11 rounded-1 px-3 pb-1 pt-1">
                      Login
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={8} sm={12}>
              <div className="text-center">
                <h5 style={{ fontWeight: 500 }}>Good Morning, Brendon</h5>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CtmsDashboardData;
