import React from "react";
import { Form } from "react-bootstrap";

const FilterAuditTrailData = ({
    filterRecentStudyActivityData,
    status,
    setStatus,
    type,
    setType,
    category,
    setCategory,
    activityCategory,
    activityBy,
    setActivityBy,
    activityUser,
    handleResetFilterData,
}) => {
    return (
        <div>
            <div
                className="p-3 overflow-auto"
                style={{ height: "calc(100vh - 89px)" }}
            >
                <Form.Group className="mb-2">
                    <Form.Label className="mb-1">
                        <b>Category</b>
                    </Form.Label>
                    <Form.Select
                        className="p-1 px-2 rounded-1 text-capitalize"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                    >
                        <option value="">Select Category</option>
                        {activityCategory?.map((item, index) => (
                            <option className="text-capitalize" key={index} value={item}>
                                {item}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label className="mb-1">
                        <b>Activity By</b>
                    </Form.Label>
                    <Form.Select
                        className="p-1 px-2 rounded-1"
                        value={activityBy}
                        onChange={(e) => setActivityBy(e.target.value)}
                    >
                        <option value="">Select User</option>
                        {activityUser?.map((item, index) => (
                            <option key={index} value={item?.userId}>
                                {item?.name}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </div>
            <div className="p-2 bg-light shadow-sm hstack justify-content-end gap-2">
                <button
                    onClick={() => handleResetFilterData()}
                    className="border border-danger btn btn-outline-danger fontSize12 rounded-1 p-1 px-2"
                >
                    Reset
                </button>
                <button
                    className="border fontSize12 rounded-1 p-1 px-2 updateProfile"
                    disabled={!(status || type || category || activityBy)}
                    onClick={filterRecentStudyActivityData}
                >
                    Filter
                </button>
            </div>
        </div>
    );
};

export default FilterAuditTrailData;
