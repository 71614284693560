import React from "react";
import NewTreatmetTable from "../TreatmentDesign/NewTreatmetTable";
import { Container, Table } from "react-bootstrap";
import NewFactorTableData from "../FactorDesign/NewFactorTableData";

const FinalTrialDesign = () => {
  return (
    <>
        <div className="trial-design-header">Trial Design</div>
        <div className="parameter_data mb-2">
          <Container fluid className="mb-2">
            <div className="mb-2 mt-3 allTreatmentClass">Treatments</div>
            <Container>
              <NewTreatmetTable />
            </Container>
            <div className="mb-2 mt-3 allTreatmentClass">Factors</div>
            <Container>
              <NewFactorTableData />
            </Container>
            <div className="mb-2 mt-3 allTreatmentClass">Blinding</div>
            <Container>
              <Table className="w-100  custom-table">
                <tbody>
                  <tr>
                    <td>Blinding:</td>
                    <td>Double-blinded using 2 treatment codes per site</td>
                  </tr>
                  <tr>
                    <td>Blinding Table Layout:</td>
                    <td>1 block of size 2</td>
                  </tr>
                  <tr>
                    <td>Code Pattern:</td>
                    <td>DR####sr</td>
                  </tr>
                  <tr>
                    <td>Code Selection:</td>
                    <td>Sequential</td>
                  </tr>
                  <tr>
                    <td>Code Reuse:</td>
                    <td>Yes</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
            <div className="mb-2 mt-3 allTreatmentClass">Randomization</div>
            <Container>
              <Table className="w-100  custom-table">
                <tbody>
                  <tr>
                    <td>Randomization Method:</td>
                    <td>Permuted Blocks</td>
                  </tr>
                  <tr>
                    <td>Block Size(s):</td>
                    <td>4, 4, 4, 8</td>
                  </tr>
                  <tr>
                    <td>Compensate Rejections:</td>
                    <td>yes</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
            <div className="mb-2 mt-3 allTreatmentClass">
              Random Number Generator
            </div>
            <Container>
              <Table className="w-100  custom-table">
                <tbody>
                  <tr>
                    <td>Random Number Seed:</td>
                    <td>23</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
            <div className="mb-2 mt-3 allTreatmentClass">
              Subgroup Building for Stratification
            </div>
            <Container>
              <Table className="w-100  custom-table">
                <tbody>
                  <tr>
                    <td>Trial Site:</td>
                    <td>Consider site as a factor</td>
                  </tr>
                  <tr>
                    <td>Combination Depth:</td>
                    <td>1</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
            <div className="mb-2 mt-3 allTreatmentClass">
              Data Entry and Randomization Numbering
            </div>
            <Container>
              <Table className="w-100  custom-table">
                <tbody>
                  <tr>
                    <td>Subject-ID:</td>
                    <td>Structured text</td>
                  </tr>
                  <tr>
                    <td>Subject-ID Structure:</td>
                    <td>125f</td>
                  </tr>
                  <tr>
                    <td>Subject-ID Placeholder:</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>Subject-ID Must Be Unique Within the Trial:</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Randomization Number Offset:</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td>Confirm Subject Eligibility:</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Tagging Expression:</td>
                    <td>6</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </Container>
        </div>
    </>
  );
};
export default FinalTrialDesign;
