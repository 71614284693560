import React from "react";
import { Form } from "react-bootstrap";
const StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
let role = JSON.parse(localStorage.getItem("role"));
let previousRole = JSON.parse(localStorage.getItem("previousRole"));
let studyStatusData = JSON.parse(localStorage.getItem("studyStatusData"));

console.log(":>>>role", role);
console.log(":>>>previousRole", previousRole);


const Profilers1 = ({ projectID, clientID, noApps }) => (
  <div className={`justify-content-between hstack gap-3 p-2 ${noApps ? "py-2" : ""}`}>
    <Form.Label className="m-0">
      <i className="fa-solid fa-user-tie live__mode__color" />
      <span className="text-white client1">{clientID}</span>
    </Form.Label>

    <Form.Label className="m-0">
      <i className="fa-solid fa-folder-open live__mode__color" />
      <span className="text-white projectid">{projectID}</span>
    </Form.Label>
    <Form.Label className="m-0">
      {(role === "ctms" || role === "studyId" || role === "edc" || role === "etmf" || role === "iwrs") && previousRole === "user" ||
        previousRole === "ctms" ? (
        <>
          <i className={(StudyMode === "DBP MODE" || StudyMode === "DV Mode") ? "fa-solid fa-circle dbp__mode__color" : ""} />
          <i className={(StudyMode === "SDV Live Mode" || StudyMode === "Live Mode") ? "fa-solid fa-circle live__mode__color" : ""} />
          <i className={(StudyMode === "SDV QC Mode" || StudyMode === "QC Mode") ? "fa-solid fa-circle qc__mode__color" : ""} />
          <span className="text-white projectid"> {StudyMode}</span>
        </>
      ) : (
        ""
      )}
    </Form.Label>
    <Form.Label className="m-0">
      <i className="fa-solid fa-folder live__mode__color" />
      <span className="text-white projectid capitalize">Status : {studyStatusData}</span>
    </Form.Label>
  </div>
);

export default Profilers1;