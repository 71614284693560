import React, { useEffect, useState } from "react";
import { Col, Container, Form, Offcanvas, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { studyOnboardingService } from "../../../../../../../Services/studyServices/studySubjectsServices";

const SiteSubjectFilter = ({
  Show,
  Hide,
  Title,
  setFilter,
  filter,
  setStudyDynamicRow,
  getStudySubjectRowData,
  studyDynamicColumnID,
  studySubjectState,
}) => {
  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const { studyId } = useParams();
  const [status, setStatus] = useState("");
  const [queryStatus, setQueryStatus] = useState("");
  const [loader, setLoader] = useState(false);

  const filterStudySubjectRowData = async () => {
    let userData = {};
    setLoader(true);
    userData.studyId = studyId;
    userData.studyMode = StudyMode;
    userData.columnId = studyDynamicColumnID;
    userData.siteUniqueId = studySubjectState;
    userData.status = status;
    userData.queryStatus = queryStatus;
    let data = await studyOnboardingService.filterStudySubjectRowData(userData);
    let initialData = Object.values(data.data === undefined ? "" : data?.data);
    let finalData = initialData?.sort()?.reverse();
    setStudyDynamicRow(finalData);
    setFilter(true);
    setLoader(false);
    Hide();
  };

  const clearFilter = () => {
    getStudySubjectRowData();
    setFilter(false);
    Hide();
  };

  useEffect(() => {
    if (!filter) {
      setStatus("");
      setQueryStatus("");
    }
  }, [filter]);
  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="p-3 overflow-auto"
            style={{ height: "calc(100vh - 89px)" }}
          >
            <Container fluid>
              <Row className="mb-2">
                <Col md={4} className="text-end">
                  <Form.Label>
                    <b>Site Group :</b>
                  </Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Select onChange={(e) => setStatus(e.target.value)}>
                    <option value="">Site Status</option>
                    <option selected={status == "ongoing"} value="ongoing">
                      Ongoing
                    </option>
                    <option selected={status == "completed"} value="completed">
                      Completed
                    </option>
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col md={4} className="text-end">
                  <Form.Label>
                    <b>Query Status :</b>
                  </Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Select onChange={(e) => setQueryStatus(e.target.value)}>
                    <option value="">Select Query Status</option>
                    <option selected={queryStatus == "open"} value="open">
                      Open
                    </option>
                    <option selected={queryStatus == "closed"} value="closed">
                      Closed
                    </option>
                    <option
                      selected={queryStatus == "cancelled"}
                      value="cancelled"
                    >
                      Cancelled
                    </option>
                  </Form.Select>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="p-2 bg-light shadow-sm hstack justify-content-end gap-2">
            <button
              onClick={filterStudySubjectRowData}
              disabled={loader || !(status || queryStatus)}
              className="border fontSize12 rounded-1 p-1 px-2 updateProfile"
            >
              {loader ? (
                <Spinner
                  className="me-1"
                  style={{ width: 15, height: 15 }}
                  animation="border"
                />
              ) : (
                <i className="fa-solid fa-check me-1"></i>
              )}
              Apply Filter
            </button>
            {filter && (
              <button
                onClick={clearFilter}
                className="border border-danger btn btn-outline-danger fontSize12 rounded-1 p-1 px-2"
              >
                <i className="fa-solid fa-xmark me-1"></i>
                Clear Filter
              </button>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default SiteSubjectFilter;
