import React from "react";
import SplitPane from "react-split-pane";
import { NoDataFoundTable } from "../../../../../../../NoDataFoundTable/NoDataFoundTable";

const StudySiteReport = () => {
  return (
    <>
      <SplitPane split="vertical" minSize={20} defaultSize={"100%"}>
        <NoDataFoundTable MSG="No Report Found" />
      </SplitPane>
    </>
  );
};

export default StudySiteReport;
