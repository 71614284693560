import React, { useState } from "react";
import { Alert, Form, Spinner } from "react-bootstrap";
import { FaCirclePlus } from "react-icons/fa6";
import { CircleXIcon } from "../../../../../../../Assets/Icons/GlobalIcons";

const AddUpdateSiteSubjectColumn = ({
  addFormFields,
  removeFormFields,
  formValues,
  handleSubmit0,
  handleSubmit,
  updateStudyDynamicColumn,
  studyDynamicColumn,
  addHandleChange,
  updateHandleChange,
  removeAddUpdateFields,
  loadSpan,
  statusCode,
  customMessage,
}) => {
  let checkUpdateDunamicValue = (updateStudyDynamicColumn || [])?.map(
    (item) => !item.name || !item.columnValue || !item.types
  );
  let checkUpdateFilledData = checkUpdateDunamicValue?.filter(
    (item) => item === false
  );
  let dynamicUpdateConditionData =
    updateStudyDynamicColumn?.length === checkUpdateFilledData?.length;

  let checkAddDunamicValue = (formValues || [])?.map(
    (item) => !item.name || !item.columnValue || !item.types
  );
  let checkAddFilledData = checkAddDunamicValue?.filter(
    (item) => item === false
  );
  let dynamicAddConditionData =
    formValues?.length === checkAddFilledData?.length;

  const [options, setOptions] = useState([{ option: "" }]);

  const handleInputChange = (index, event) => {
    const values = [...options];
    values[index][event.target.name] = event.target.value;
    setOptions(values);
  };

  const handleAddOption = () => {
    setOptions([...options, { option: "" }]);
  };

  const handleRemoveOption = (index) => {
    const values = [...options];
    values.splice(index, 1);
    setOptions(values);
  };
  return (
    <div>
      <Form.Group className="p-2">
        <Form.Label className="mb-1 fw-bold">
          {studyDynamicColumn?.length > 0 ? "Update" : "Add"} Column Data{" "}
          <span className="text-danger">*</span>
          <FaCirclePlus
            className="ms-2 CP"
            title="Add More"
            onClick={addFormFields}
          />
        </Form.Label>
        {studyDynamicColumn?.length > 0
          ? updateStudyDynamicColumn?.map(({ name, columnValue, types }, i) => (
              <>
                <div className="hstack gap-2 mb-2" key={i}>
                  <Form.Control
                    className="rounded-1"
                    placeholder="Enter Column Label"
                    name="name"
                    value={name}
                    onChange={(e) => updateHandleChange(e, i)}
                  />
                  <Form.Control
                    type="text"
                    className="rounded-1"
                    placeholder="Enter Value"
                    name="columnValue"
                    value={columnValue}
                    onChange={(e) => updateHandleChange(e, i)}
                  />
                  <Form.Select
                    style={{ width: 120 }}
                    className="rounded-1"
                    id="autoSizingSelect"
                    name="types"
                    onChange={(e) => updateHandleChange(e, i)}
                  >
                    <option hidden>Select Type</option>
                    <option selected={types === "text" && "text"} value="text">
                      Text
                    </option>
                    <option
                      selected={types === "number" && "number"}
                      value="number"
                    >
                      Number
                    </option>
                    <option selected={types === "date" && "date"} value="date">
                      Date
                    </option>
                    <option selected={types === "time" && "time"} value="time">
                      Time
                    </option>
                    <option
                      selected={types === "dropdown" && "dropdown"}
                      value="dropdown"
                    >
                      Dropdown
                    </option>
                  </Form.Select>

                  {updateStudyDynamicColumn?.length > 1 && (
                    <i
                      className="fa fa-trash-can ms-2 CP text-danger inactiveclient fontSize12"
                      title="Remove Option"
                      onClick={(e) => removeAddUpdateFields(e, i)}
                    ></i>
                  )}
                </div>
                {types === "dropdown" && (
                  <div
                    className="pe-4"
                    style={{ marginTop: -10, marginRight: 3 }}
                  >
                    <div className="shadow-sm p-2 rounded-1 border border-top-0 rounded-top-0 mb-2">
                      <Form.Group className="mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label className="mb-1 fw-bold fontSize12">
                            Option
                            <i
                              className="fa fa-square-plus ms-1"
                              title="Add More Option"
                              style={{ cursor: "pointer" }}
                              onClick={handleAddOption}
                            ></i>
                          </Form.Label>
                        </div>
                        {options.map((field, index) => (
                          <div key={index} className="hstack gap-2 mb-2">
                            <Form.Control
                              type="text"
                              placeholder="Enter Option"
                              name="option"
                              className="rounded-1"
                              value={field.option}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                            {options.length > 1 && (
                              <i
                                className="fa fa-trash-can ms-2 inactiveclient fontSize12"
                                title="Remove Option"
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => handleRemoveOption(index)}
                              ></i>
                            )}
                          </div>
                        ))}
                      </Form.Group>
                    </div>
                  </div>
                )}
              </>
            ))
          : formValues?.map(({ name, columnValue, types }, i) => (
              <>
                <div className="hstack gap-2 mb-2" key={i}>
                  <Form.Control
                    className="rounded-1"
                    placeholder="Enter Column Label"
                    name="name"
                    value={name}
                    onChange={(e) => addHandleChange(e, i)}
                  />
                  <Form.Control
                    type="text"
                    className="rounded-1"
                    placeholder="Enter Column Value"
                    name="columnValue"
                    value={columnValue}
                    onChange={(e) => addHandleChange(e, i)}
                  />
                  <Form.Select
                    style={{ width: 120 }}
                    className="rounded-1"
                    id="autoSizingSelect"
                    name="types"
                    onChange={(e) => addHandleChange(e, i)}
                  >
                    <option hidden>Select</option>
                    <option selected={types === "text" && "text"} value="text">
                      Text
                    </option>
                    <option
                      selected={types === "number" && "number"}
                      value="number"
                    >
                      Number
                    </option>
                    <option selected={types === "date" && "date"} value="date">
                      Date
                    </option>
                    <option selected={types === "time" && "time"} value="time">
                      Time
                    </option>
                    <option
                      selected={types === "dropdown" && "dropdown"}
                      value="dropdown"
                    >
                      Dropdown
                    </option>
                  </Form.Select>
                  <i
                    className="fa fa-trash-can ms-2 CP text-danger inactiveclient fontSize12"
                    title="Remove Option"
                    onClick={(e) => removeFormFields(e, i)}
                  ></i>
                </div>
                {types === "dropdown" && (
                  <div
                    className="pe-4"
                    style={{ marginTop: -10, marginRight: 3 }}
                  >
                    <div className="shadow-sm p-2 rounded-1 border border-top-0 rounded-top-0 mb-2">
                      <Form.Group className="mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label className="mb-1 fw-bold fontSize12">
                            Option
                            <i
                              className="fa fa-square-plus ms-1"
                              title="Add More Option"
                              style={{ cursor: "pointer" }}
                              onClick={handleAddOption}
                            ></i>
                          </Form.Label>
                        </div>
                        {options.map((field, index) => (
                          <div key={index} className="hstack gap-2 mb-2">
                            <Form.Control
                              type="text"
                              placeholder="Enter Option"
                              name="option"
                              className="rounded-1"
                              value={field.option}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                            {options.length > 1 && (
                              <i
                                className="fa fa-trash-can ms-2 CP text-danger inactiveclient fontSize12"
                                title="Remove Option"
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => handleRemoveOption(index)}
                              ></i>
                            )}
                          </div>
                        ))}
                      </Form.Group>
                    </div>
                  </div>
                )}
              </>
            ))}
        <div className="text-end my-2">
          {studyDynamicColumn?.length > 0 ? (
            <button
              className="submitbutton border"
              onClick={handleSubmit0}
              disabled={loadSpan || !dynamicUpdateConditionData}
              title={
                !dynamicUpdateConditionData ? "Fill all mandatory fields" : ""
              }
            >
              {loadSpan ? (
                <Spinner
                  className="me-1"
                  style={{ width: 15, height: 15 }}
                  animation="border"
                />
              ) : (
                <i className="fa-solid fa-pen-to-square me-1"></i>
              )}
              Update Column
            </button>
          ) : (
            formValues?.length > 0 && (
              <button
                className="submitbutton border"
                onClick={handleSubmit}
                disabled={loadSpan || !dynamicAddConditionData}
                title={
                  !dynamicAddConditionData ? "Fill all mandatory fields" : ""
                }
              >
                {loadSpan ? (
                  <Spinner
                    className="me-1"
                    style={{ width: 15, height: 15 }}
                    animation="border"
                  />
                ) : (
                  <i className="fa-solid fa-square-plus me-1"></i>
                )}
                Add Column
              </button>
            )
          )}
        </div>
        {customMessage && (
          <Alert variant={statusCode === 200 ? "success" : "danger"}>
            {customMessage}
          </Alert>
        )}
      </Form.Group>
    </div>
  );
};

export default AddUpdateSiteSubjectColumn;
