import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { elearningServices } from "../../../Services/elearningServices";

const AddLearning = ({studyId, getStudyELearningData}) => {
  const [trainingName, setTrainingName] = useState("");
  const [description, setDescription] = useState("");
  const [completionDays, setCompletionDays] = useState("");
  const [loader, setLoader] = useState("");


  const addStudyElearning = async (e) => {
    e.preventDefault();
    setLoader(true);
    let userData = {};
    userData.studyID = studyId
    userData.learningName = trainingName;
    userData.description = description;
    userData.completionDays = completionDays;
    let data = await elearningServices.addStudyElearning(userData);
    setLoader(false);
    getStudyELearningData();
    if (data?.statusCode === 200) {
    } else {
      setLoader(false);
    }
  };


  return (
    <div className="p-2">
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Training Name <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          value={trainingName}
          placeholder="Enter Traning Name"
          className="rounded-1 fontSize12"
          onChange={(e) => setTrainingName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Description <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          value={description}
          className="rounded-1 fontSize12"
          placeholder="Enter Training Description"
          onChange={(e) => setDescription(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          No. Of Completion Days <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          value={completionDays}
          className="rounded-1 fontSize12"
          placeholder="Enter No. Of Completion Days"
          onChange={(e) => setCompletionDays(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="text-end">
        <button
          className="submitbutton rounded-1 border"
          disabled={!trainingName || !description}
          onClick={addStudyElearning}
        >
          {loader ? (
            <Spinner className="me-2" style={{ width: 15, height: 15 }} />
          ) : (
            <i className="fa-solid fa-square-plus me-2"></i>
          )}
          Add Training
        </button>
      </Form.Group>
    </div>
  );
};

export default AddLearning;
