import React, { useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import FinalTrialDesign from "./FinalTrialDesign/FinalTrialDesign";

const NewTrialParametersDesign = () => {
  const [error, setError] = useState("");
  const [depth, setDepth] = useState("");
  const [blinding, setBlinding] = useState("");
  const [blockSize, setBlockSize] = useState("");
  const [multipleBlock, setMultipleBlock] = useState("");
  const [randomMethods, setRandomMethods] = useState("");
  const [showFinalDesign, setShowFinalDesign] = useState(false);
  const [dataEntry, setDataEntry] = useState("unstructured-text");

  const handleBlindingChange = (event) => {
    setBlinding(event.target.value);
  };

  const handleMultipleBlock = (event) => {
    setMultipleBlock(event.target.value);
  };

  const handleRandomMethodsChange = (event) => {
    setRandomMethods(event.target.value);
  };

  const handleDataEntry = (event) => {
    setDataEntry(event.target.value);
  };

  const validateBlockSize = (value) => {
    const num = Number(value);
    if (
      !Number.isInteger(num) ||
      num < 4 ||
      num > 2147483647 ||
      num % 4 !== 0
    ) {
      return "This value should be between 4 and 2147483647 and a multiple of 4*.";
    }
    return "";
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setBlockSize(value);
    setError(validateBlockSize(value));
  };

  const validateDepth = (depth) => {
    const depthPattern = /^[01]$/;

    if (depthPattern.test(depth)) {
      setError("");
    } else {
      setError(" This value should be 0 or 1");
    }
  };

  const toggleDesign = () => {
    setShowFinalDesign((prevState) => !prevState);
  };

  return (
    <>
      {!showFinalDesign ? (
        <>
          <div className="parameter_data mt-3">
            <Container fluid>
              <Row>
                <Col md={4} sm={12}>
                  <Card
                    className="mb-3 bg-light border p-1 overflow-auto"
                    style={{ height: 480 }}
                  >
                    <Card.Body>
                      <div
                        style={{
                          borderBottom: "1px solid #d1d1d1",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                        className="mb-2"
                      >
                        Blinding
                      </div>
                      <Form.Group className="mb-2">
                        <Form.Label>Blinding:</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={handleBlindingChange}
                        >
                          <option>None (open trial)</option>
                          <option value="double-blind">
                            Single-blind Trial
                          </option>
                          <option value="double-blind">
                            Double-blind Trial
                          </option>
                          <option value="double-blind">
                            Triple-blind Trial
                          </option>
                        </Form.Select>
                      </Form.Group>
                      {blinding === "double-blind" && (
                        <div id="showDoubleBlock">
                          <Form.Label className="mb-1">Blinding Table Layout:</Form.Label>
                          <Form.Group className="mb-2">
                            <Form.Label>Code Block Size:</Form.Label>
                            <Form.Control type="number" placeholder="Enter Code Block Size" />
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label>Code Prefix:</Form.Label>
                            <Form.Control type="text" placeholder="Enter Code Prefix" />
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label>Code Suffix:</Form.Label>
                            <Form.Control type="text" placeholder="Enter Code Suffix" />
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label>Planned Unblind Date:</Form.Label>
                            <Form.Control type="date" placeholder="Enter Planned Unblind Code" />
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label>How Many of Level Approval Required:</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                            >
                              <option>1</option>
                              <option value="double-blind">
                                1
                              </option>
                              <option value="double-blind">
                                2
                              </option>
                              <option value="double-blind">
                                3
                              </option>
                              <option value="double-blind">
                                4
                              </option>
                              <option value="double-blind">
                                5
                              </option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label>Unblinding To:</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                            >
                              <option>Select Unblinding For</option>
                              <option value="all users">
                                All Users
                              </option>
                              <option value="user who requested for unblinding">
                                User Who Requested For Unblinding
                              </option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label>Randomize At Once:</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                            >
                              <option>Select Randomize</option>
                              <option value="yes">
                                Yes
                              </option>
                              <option value="no">
                                No
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>

                <Col md={4} sm={12}>
                  <Card
                    className="mb-3 bg-light border p-1 overflow-auto"
                    style={{ height: 480 }}
                  >
                    <Card.Body>
                      <div
                        style={{
                          borderBottom: "1px solid #d1d1d1",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                        className="mb-2"
                      >
                        Randomization
                      </div>
                      <Form.Group className="mb-2">
                        <Form.Label>Randomization Method:</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={handleRandomMethodsChange}
                        >
                          <option value="completely-random">
                            Completely at Random
                          </option>
                          <option value="permuted-blocks">
                            Permuted Blocks
                          </option>
                        </Form.Select>
                      </Form.Group>
                      {randomMethods === "permuted-blocks" && (
                        <>
                          <Form.Group className="mb-2">
                            <Form.Label>Block Size (1):</Form.Label>
                            <Form.Control type="text" placeholder="Enter Block Size" />
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label>Block Size (2):</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Block Size"
                              value={blockSize}
                              onChange={handleChange}
                              isInvalid={!!error}
                            />
                            {error && (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "11px",
                                  marginTop: "5px",
                                }}
                              >
                                {error}
                              </div>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label>Block Size (3):</Form.Label>
                            <Form.Control type="text" placeholder="Enter Block Size" />
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label>Block Size (4):</Form.Label>
                            <Form.Control type="text" placeholder="Enter Block Size" />
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label>Rejections:</Form.Label>
                            <Form.Check
                              type="checkbox"
                              placeholder="Enter Rejections"
                              label={
                                "Compensate imbalance introduced by rejections"
                              }
                            />
                          </Form.Group>
                        </>
                      )}
                      <Form.Label className="mb-1">Random Number:</Form.Label>
                      <Form.Group className="mb-2">
                        <Form.Label>Code Prefix:</Form.Label>
                        <Form.Control type="text" placeholder="Enter Code Prefix" />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label>Code Suffix:</Form.Label>
                        <Form.Control type="text" placeholder="Enter Code Suffix" />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label>Starting Number:</Form.Label>
                        <Form.Control type="number" placeholder="Enter Starting Number" />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label>Random Number Seed:</Form.Label>
                        <Form.Control type="number" placeholder="Enter Random Number Seed" />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4} sm={12}>
                  <Card
                    className="mb-3 bg-light border p-1 overflow-auto"
                    style={{ height: 480 }}
                  >
                    <Card.Body>
                      <div
                        style={{
                          borderBottom: "1px solid #d1d1d1",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                        className="mb-2"
                      >
                        Data Entry and Randomization Numbering
                      </div>
                      <Form.Group className="mb-2">
                        <Form.Label>Subject-ID Structure:</Form.Label>
                        <Form.Control type="text" placeholder="Enter Subject-ID Structure" />
                      </Form.Group>

                      <Form.Group className="mb-2">
                        <Form.Label>Code Prefix:</Form.Label>
                        <Form.Control type="text" placeholder="Enter Code Prefix" />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label>Code Suffix:</Form.Label>
                        <Form.Control type="text" placeholder="Enter Code Suffix" />
                      </Form.Group>

                      <Form.Group className="mb-2">
                        <Form.Label>Data Entry Options:</Form.Label>
                        <Form.Check
                          type="checkbox"
                          label="Confirm Subject Eligibility"
                        />
                        <Form.Check
                          type="checkbox"
                          label="Ensure that Subject-ID is unique within the entire trial"
                        />
                      </Form.Group>

                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <div className="p-2 hstack justify-content-end gap-2 position-sticky bottom-0">
                <button
                  className="updateProfile"
                  style={{ width: 100, fontSize: "14px", marginRight: "5px" }}
                // onClick={toggleDesign}
                >
                  Save
                </button>
                <button
                  className="updateProfile"
                  style={{ width: 100, fontSize: "14px", marginRight: "5px" }}
                >
                  Cancel
                </button>
              </div>
            </Container>
          </div>
        </>
      ) : (
        <FinalTrialDesign />
      )}
    </>
  );
};

export default NewTrialParametersDesign;
