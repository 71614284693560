import React from "react";
import { Table } from "react-bootstrap";
import { NoDataFoundTable } from "../../../../../NoDataFoundTable/NoDataFoundTable";

const SubjectMetadata = ({ studyDynamicColumn }) => {
  return (
    <>
      {studyDynamicColumn?.length > 0 ?
        <Table className="m-0 custom-table" bordered>
          <thead>
            <tr>
              <th style={{ width: 45 }}>S No.</th>
              <th>Column</th>
              <th>Value</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {studyDynamicColumn?.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.columnValue}</td>
                <td>{item.types}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        :
        <NoDataFoundTable MSG="Subject Column not found" />
      }
    </>
  );
};

export default SubjectMetadata;
