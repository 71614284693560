import React, { useState } from "react";
import { Form, Table } from "react-bootstrap";
import Pagination from "../../ETMF/Pagination/Pagination";
import Moment from "react-moment";
import { elearningServices } from "../../../Services/elearningServices";
import Swal from "sweetalert2";

const ELearningDataTable = ({
  handleRowClick,
  handleAddNewTab,
  elearningData,
  getStudyELearningData,
  handleUpdate,
  viewTypeData,
  trainingId
}) => {
  const deleteStudyELearningData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await elearningServices.deleteStudyELearningData(id);
        if (data?.statusCode === 200) {
          getStudyELearningData();
        }
      }
    });
  };

  const updateStudyELearningStatus = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Data Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.id = id;
        data.status = status;
        let results = await elearningServices.updateStudyELearningStatus(data);
        if (results?.statusCode === 200) {
          getStudyELearningData();
        }
      }
    });
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = elearningData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(elearningData?.length / itemsPerPage);

  return (
    <>
      <Table id="resizable-table" striped hover className="m-0 custom-table">
        <thead className="thead-sticky top-0">
          <tr>
            <th style={{ width: 45 }}>Sr.</th>
            <th>Training Name</th>
            <th>Description</th>
            <th>No. of completion days</th>
            <th style={{ width: 80 }}>Status</th>
            <th>Created Date | Time</th>
            <th>Modified Date | Time</th>
            {viewTypeData === "ctms" &&
              <th style={{ width: 50 }} className="text-center">
                Edit
              </th>
            }
            <th style={{ width: 50 }} className="text-center">
              View
            </th>
            {viewTypeData === "ctms" &&
              <th style={{ width: 50 }} className="text-center">
                Delete
              </th>
            }
          </tr>
        </thead>
        <tbody>
          {result?.map((item, index) => (
            <tr key={index} className={`CP ${trainingId === item?._id ? "highlighted" : ""}`}>
              <td onClick={() => handleRowClick(item?._id)}>{index + 1}</td>
              <td onClick={() => handleRowClick(item?._id)}>{item.name}</td>
              <td onClick={() => handleRowClick(item?._id)}>{item.description}</td>
              <td onClick={() => handleRowClick(item?._id)}>{item.completionDays}</td>
              <td onClick={() => handleRowClick(item?._id)}>
                <Form.Select
                  disabled={viewTypeData !== "ctms"}
                  className={`border-0 p-0 rounded-0 bg-transparent ${item.status.toLowerCase()}`}
                  value={item.status}
                  onChange={(e) =>
                    updateStudyELearningStatus(item?.id, e.target.value)
                  }
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Select>
              </td>
              <td onClick={() => handleRowClick(item?._id)}>
                <Moment format="DD MMM YYYY | HH:MM">{item.createdAt}</Moment>
              </td>
              <td onClick={() => handleRowClick(item?._id)}>
                <Moment format="DD MMM YYYY | HH:MM">{item.updatedAt}</Moment>
              </td>
              {viewTypeData === "ctms" &&
                <td className="text-center">
                  <button
                    onClick={() => handleUpdate(item)}
                    className="text-white border-primary bg-primary border-0"
                    style={{
                      fontSize: 10,
                      borderRadius: 3,
                      padding: "2px 8px",
                    }}
                  >
                    Edit
                  </button>
                </td>
              }
              <td className="text-center">
                <button
                  onClick={() =>
                    handleAddNewTab(
                      `${item.name}`,
                      "fa-solid fa-folder",
                      `trainingDataManagement_${item?._id}`,
                      { traningId: item?._id }
                    )
                  }
                  className="text-white border-success bg-success border"
                  style={{
                    fontSize: 10,
                    borderRadius: 3,
                    padding: "2px 8px",
                  }}
                >
                  View
                </button>
              </td>
              {viewTypeData === "ctms" &&
                <td className="text-center">
                  <button
                    onClick={() => deleteStudyELearningData(item?._id)}
                    className="text-white border-danger bg-danger border"
                    style={{
                      fontSize: 10,
                      borderRadius: 3,
                      padding: "2px 8px",
                    }}
                  >
                    Delete
                  </button>
                </td>
              }
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={elearningData?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default ELearningDataTable;
