import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import ReceiverImage from "../../../../../../../../img/man-highlighted.png";
import SenderImage from "../../../../../../../../img/man.png";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { studyOnboardingService } from "../../../../../../../../Services/studyServices/studySubjectsServices";
import { studySettingControlServices } from "../../../../../../../../Services/studyServices/studySettingControlServices";

const StudySubjectQueryDiscussionComponent = ({
  isFullScreen,
  setStatus,
  getBackgroundColor,
  status,
  queryId,
  getStudySubjectQueryList,
  queryGenerator,
  currentUserId,
  subjectId,
  subjectUniqueId,
  studyId,
  StudyMode,
  getStudySubjectRowData,
}) => {
  const [discussionList, setDiscussionList] = useState([]);
  const [responseList, setResponseList] = useState([]);
  const [message, setMessage] = useState("");
  const [customMessage, setCustomMessage] = useState("");
  const updateStatusStudySubjectQuery = async (status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to update Query Ticket Status?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.studyUniqueId = subjectUniqueId;
        userData.status = status;
        userData.studyId = studyId;
        userData.queryId = queryId;
        userData.mode = StudyMode;
        let data = await studyOnboardingService.updateStatusStudySubjectQuery(
          userData
        );
        if (data.statusCode === 200) {
          getStudySubjectQueryList();
          getStudySubjectRowData();
        }
      }
    });
  };

  const getStudySubjectQueryDiscussionList = async () => {
    let data = await studyOnboardingService.getStudySubjectQueryDiscussionList(
      queryId,
      studyId
    );
    setDiscussionList(data?.data);
  };

  const sendStudySubjectQueryDiscussion = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.queryId = queryId;
    userData.message = message;
    userData.mode = StudyMode;
    userData.studyUniqueId = subjectUniqueId;
    userData.type = queryGenerator === currentUserId ? "sender" : "receiver";
    let data = await studyOnboardingService.sendStudySubjectQueryDiscussion(
      userData
    );
    if (data?.statusCode === 200) {
      getStudySubjectQueryDiscussionList(queryId);
      setMessage("");
      setCustomMessage("");
      getStudySubjectQueryList();
    }
  };

  const getActiveQueryResponseMessageList = async () => {
    let data =
      await studySettingControlServices.getActiveQueryResponseMessageList(
        studyId,
        subjectId
      );
    setResponseList(data?.data);
  };

  useEffect(() => {
    getStudySubjectQueryDiscussionList();
  }, [queryId]);

  useEffect(() => {
    getActiveQueryResponseMessageList();
  }, [subjectId]);

  return (
    <>
      <div
        className={`position-relative ${
          isFullScreen ? "pdf_viwer_section_full" : ""
        }`}
      >
        <div
          className="hstack justify-content-between border-bottom p-1 position-sticky top-0"
          style={{ background: "#b8cfdf" }}
        >
          <span className="m-0">
            <b>#{queryId}</b>{" "}
            <i
              onClick={() => getStudySubjectQueryDiscussionList(queryId)}
              className="fa-solid fa-rotate text-white rounded-circle p-1"
              title="Refresh Query Discussion"
              style={{
                backgroundColor: "#103c5e",
                fontSize: 8,
              }}
            ></i>
          </span>
          <div className="hstack gap-2 text-nowrap">
            <span style={{ fontSize: 10 }}>
              <b>Status :</b>{" "}
            </span>
            <Form.Select
              className="rounded-1 border-0"
              value={status}
              disabled={status === "cancel"}
              onChange={(e) => {
                setStatus(e.target.value);
                updateStatusStudySubjectQuery(e.target.value);
              }}
              style={{
                backgroundColor: getBackgroundColor(status),
                fontSize: 11,
                padding: "2px 32px 2px 8px",
              }}
            >
              <option selected={status === "open"} value="open">
                Open
              </option>
              {!(status === "closed") && (
                <option selected={status === "inprogress"} value="inprogress">
                  In Progress
                </option>
              )}
              {!(status === "closed" || status === "inprogress") && (
                <option selected={status === "cancel"} value="cancel">
                  Cancel
                </option>
              )}
              <option selected={status === "closed"} value="closed">
                Closed
              </option>
            </Form.Select>
          </div>
        </div>
        <div
          className={`chat ${isFullScreen ? "chatFullHeight" : "chatHeight"}`}
        >
          {discussionList?.map((item, index) => (
            <div key={index}>
              {!(item.messageByuserId === queryGenerator) ? (
                <div className="receiverSays">
                  <div className="text-box-receiver">
                    <div className="text">
                      <div className="d-flex gap-2 mb-3">
                        <img
                          src={ReceiverImage}
                          alt="Receiver Img"
                          title={`Sender Name: ${item.messageBy} \nSender Id: ${item.messageuserIdBy}`}
                        />
                        <div style={{ lineHeight: "15px" }}>
                          <p>{item.message}</p>
                          <span>
                            <Moment format="DD MMM YYYY | HH:mm:ss">
                              {item.createdAt}
                            </Moment>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="userSays">
                  <div className="text">
                    <div className="d-flex gap-2 mb-3">
                      <div style={{ lineHeight: "15px" }}>
                        <p>{item.message}</p>
                        <span>
                          <Moment format="DD MMM YYYY | HH:mm:ss">
                            {item.createdAt}
                          </Moment>
                        </span>
                      </div>
                      <img
                        src={SenderImage}
                        alt="Sender Img"
                        title={`Receiver Name: ${item.messageBy} \nReceiver Id: ${item.messageuserIdBy}`}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div
          className="border-top border-bottom position-sticky bg-light"
          style={{ bottom: 25 }}
        >
          <div className="hstack p-1 gap-2">
            {!customMessage && (
              <Form.Select
                disabled={status === "closed" || status === "cancel"}
                onChange={(e) => {
                  setMessage(e.target.value);
                  setCustomMessage(e.target[e.target.selectedIndex].title);
                }}
              >
                <option value="">
                  {status === "open"
                    ? "Select Message Suggestion"
                    : status === "closed"
                    ? "Query has been Closed"
                    : "Query has been Cancelled"}
                </option>
                <option
                  value=""
                  title="customMessage"
                  className="bg-secondary text-white"
                >
                  Custom Message
                </option>
                {responseList?.map((data, index) => (
                  <option key={index} value={data.queryResponseMessage}>
                    {data.queryResponseMessage}
                  </option>
                ))}
              </Form.Select>
            )}
            {customMessage === "customMessage" && (
              <Form.Control
                className="rounded-1"
                type="text"
                value={
                  status === "open"
                    ? message
                    : status === "closed"
                    ? "Query has been Closed"
                    : "Query has been Cancelled"
                }
                disabled={status === "closed" || status === "cancel"}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type query...."
              />
            )}
            <button
              disabled={
                !message && (status === "closed" || status === "cancel")
              }
              onClick={sendStudySubjectQueryDiscussion}
              className="updateProfile"
              style={{ padding: "5px 10px" }}
            >
              <i className="fa-regular fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudySubjectQueryDiscussionComponent;
