import React, { useState } from "react";
import SplitPane from "react-split-pane";
import UseToggle from "../../../../Hooks/UseToggle";
import AddNewfactorsData from "./AddNewfactorsData";
import NewFactorTableData from "./NewFactorTableData";
import { SpinerDataLoader } from "../../../NoDataFoundTable/NoDataFoundTable";

const NewTrialfactorsDesign = ({ onNext, onBack, onMouseDown, tableRef }) => {
  const { setToggle } = UseToggle();
  const [loader, setLoader] = useState(false);
  const [updateType, setUpdateType] = useState("");
  const [isSectionVisible, setIsSectionVisible] = useState(false);

  const handleAddNewLearning = () => {
    setUpdateType("add");
  };

  const [factors, setFactors] = useState([]);

  const addNewFactor = (newFactor) => {
    setFactors([...factors, newFactor]);
    setToggle(false);
  };

  return (
    <>
      <div className="position-relative">
        <div className="p-1 hstack justify-content-between border-bottom">
          <div className="hstack gap-2 toolbar">
            {updateType ? (
              <i
                onClick={() => setUpdateType("")}
                title="Table Full Mode"
                className="fa-solid fa-table-list"
              ></i>
            ) : (
              <i
                onClick={handleAddNewLearning}
                title="Add New Factors Data"
                className="fa-solid fa-square-plus CP"
              />
            )}
          </div>
        </div>
        <SplitPane
          split="horizontal"
          style={{
            height: "calc(100vh - 187px)",
          }}
          className="position-relative"
          defaultSize={isSectionVisible ? "40%" : "100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={updateType ? "30%" : "100%"}
          >
            {loader ? (
              <SpinerDataLoader />
            ) : (
              updateType && (
                <AddNewfactorsData
                  handleAddNewLearning={handleAddNewLearning}
                />
              )
            )}
            {loader ? (
              <SpinerDataLoader />
            ) : (
              <NewFactorTableData
                setIsSectionVisible={setIsSectionVisible}
                onMouseDown={onMouseDown}
                tableRef={tableRef}
                handleAddNewLearning={handleAddNewLearning}
              />
            )}
          </SplitPane>
        </SplitPane>
      </div>
    </>
  );
};

export default NewTrialfactorsDesign;
