import React, { useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";

const AddNewfactorsData = ({ onAddFactor }) => {
  const [loader, setLoader] = useState(false);
  const [factor, setFactor] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);

  const handleOptionChange = (oIndex, value) => {
    const newOptions = [...options];
    newOptions[oIndex] = value;
    setOptions(newOptions);
  };

  const addOption = () => {
    setOptions([...options, ""]);
  };

  const deleteOption = (oIndex) => {
    setOptions(options.filter((_, i) => i !== oIndex));
  };

  const handleAddFactor = () => {
    if (factor && options.length > 0) {
      setLoader(true);
      onAddFactor({ factor, options });
      setFactor("");
      setOptions(["", "", "", ""]);
      setLoader(false);
    }
  };

  return (
    <>
      <div className="p-2">
        <div className="shadow-sm p-2 border rounded-1">
          <Form.Group className="mb-2">
            <div className="hstack justify-content-between">
              <Form.Label className="fw-bold my-1 fontSize12">
                Factor <span className="text-danger">*</span>
              </Form.Label>
            </div>
            <Form.Control
              type="text"
              className="rounded-1"
              value={factor}
              placeholder="Add factor"
              onChange={(e) => setFactor(e.target.value)}
            />
          </Form.Group>
          <Row>
            {options.map((option, oIndex) => (
              <React.Fragment key={oIndex}>
                <Form.Group as={Col} md={3} className="mb-2">
                  <Form.Label className="fw-bold mb-1 fontSize12">
                    Option {oIndex + 1} <span className="text-danger">*</span>
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Col} md={9} className="hstack gap-2 mb-2">
                  <Form.Control
                    type="text"
                    className="rounded-1"
                    value={option}
                    placeholder="Type Answer"
                    onChange={(e) => handleOptionChange(oIndex, e.target.value)}
                  />
                  <i
                    className="fa-solid fa-trash-can text-danger fontSize12 CP"
                    title={`Delete Option ${oIndex + 1}`}
                    onClick={() => deleteOption(oIndex)}
                  ></i>
                </Form.Group>
              </React.Fragment>
            ))}
            <Form.Group className="mb-2">
              <button
                className="border fontSize12 rounded-1 p-1 px-2 updateProfile"
                onClick={addOption}
              >
                + Add More Options
              </button>
            </Form.Group>
          </Row>
        </div>

        <Form.Group className="text-end mt-3">
          <button
            className="submitbutton rounded-1 border"
            // disabled={!factor || !options.length}
            onClick={handleAddFactor}
          >
            {loader ? (
              <Spinner className="me-2" style={{ width: 15, height: 15 }} />
            ) : (
              <i className="fa-solid fa-square-plus me-2"></i>
            )}
            Add Factor
          </button>
        </Form.Group>
      </div>
    </>
  );
};

export default AddNewfactorsData;
