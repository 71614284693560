import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import SplitPane from "react-split-pane";
import RandomizeSideData from "./RandomizeSideData";
import RandomizeTableData from "./RandomizeTableData";
import EnvelopeDesign from "../TrialDesign/EnvelopDesign/EnvelopDesign";
import { SpinerDataLoader } from "../../NoDataFoundTable/NoDataFoundTable";
import { studyAccessManagement } from "../../../Services/studyAccessManagement";
import EnvelopeNewDesign from "../TrialDesign/EnvelopDesign/EnevelopNewDesign";
import RandomizeTabData from "./RandomizeTabData";
import RandomizeFilterData from "./RandomizeFilterData";
import UseToggle from "../../../Hooks/UseToggle";

const Randomize = ({ onMouseDown, tableRef }) => {
  const [updateType, setUpdateType] = useState("");
  const { toggle, setToggle } = UseToggle();
  const [loader, setLoader] = useState(false);
  const [siteList, setSiteList] = useState([])
  const [isSectionVisible, setIsSectionVisible] = useState(false);

  const { studyId } = useParams();
  const [isTableView, setIsTableView] = useState(false)

  const handleToggleView = () => {
    setIsTableView(!isTableView);
  };

  const handleAddNewLearning = () => {
    setUpdateType("add");
  };

  const studySiteListData = async () => {
    let data = await studyAccessManagement.studySiteListData(studyId)
    setSiteList(data?.data)
  }

  useEffect(() => {
    studySiteListData()
  }, [studyId])

  return (
    <>
      <div className="position-relative">
        <div className="p-1 hstack justify-content-between border-bottom">
          <div className="hstack gap-2 toolbar">
            {updateType ? (
              <i
                onClick={() => setUpdateType("")}
                title="Table Full Mode"
                className="fa-solid fa-table-list CP"
              ></i>
            ) : (
              <i
                onClick={handleAddNewLearning}
                title="Add New Randomization"
                className="fa-solid fa-shuffle CP"
              />
            )}
            <i
              onClick={setToggle}
              title="Filter"
              className="fa-solid fa-filter CP"
            ></i>
          </div>

          <button
            onClick={handleToggleView}
            className="m-0 CP bg-main rounded-1 text-white hstack"
            title={isTableView ? "Switch to Grid View" : "Switch to Table View"}
            style={{ fontSize: 13 }}
          >
            <i className={`fa-solid ${isTableView ? "fa-toggle-on" : "fa-toggle-off"} me-2`}></i>
            {isTableView ? "Grid View" : "Table View"}
          </button>
        </div>
        {isTableView ? (
          <EnvelopeNewDesign />
        ) : (
          <SplitPane
            split="horizontal"
            style={{
              height: "calc(100vh - 187px)",
            }}
            className="position-relative"
            defaultSize={isSectionVisible ? "40%" : "100%"}
          >
            <SplitPane
              split="vertical"
              minSize={20}
              defaultSize={updateType ? "25%" : "100%"}
            >
              {loader ? (
                <SpinerDataLoader />
              ) : (
                updateType && (
                  <RandomizeSideData
                    handleAddNewLearning={handleAddNewLearning}
                    siteList={siteList}
                  />
                )
              )}
              {loader ? (
                <SpinerDataLoader />
              ) : (
                <RandomizeTableData
                  setIsSectionVisible={setIsSectionVisible}
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                />
              )}
            </SplitPane>
            {isSectionVisible && (
              <RandomizeTabData
                onMouseDown={onMouseDown}
                tableRef={tableRef}
                setIsSectionVisible={setIsSectionVisible}
              />
            )}
          </SplitPane>
        )}
      </div>
      <RandomizeFilterData
        Show={!toggle}
        Hide={setToggle}
        Title={"Randomize Properties"}
      />
    </>
  );
};

export default Randomize;
