import config from "../../config/config.json";
import saveAs from "file-saver";
import axios from "axios";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const clientLevelFormServices = {
  getLogDataEntryLists,
  getdatabaseUnFreezeForDataEntry,
  getdatabaseUnFreezeForDataEntryForLog,
  databaseUnFreezeForDataEntry,
  createClientLevelForm,
  createProjectMetaData,
  updateLogFormData,
  duplicateFormForNextVersion,
  updateStudyLevelFormsOptionsValue,
  logFormQueryGenerator,
  reviewClientLogFormField,
  sendForReviewStudyForm,
  createForms,
  updateQuery,
  replyQuery,
  queryGenerator,
  getGlobalTickets,
  getDataEntryTickets,
  getQueryList,
  getFormFieldHistoryByID,
  getStudyFormFieldHistoryByID,
  getDataEntryFreezeLock,
  getDataEntryFreezeLockForLogFrom,
  getReviewHistoryByID,
  getDataEntryFormFieldValidation,
  getReviewHistoryByID1,
  getDataEntryReviewHistoryByID,
  getLogFormFieldReviewHistoryByID,
  getFormFieldAttributeByID,
  updateStudyLevelForms,
  getStudyLevelFormReviewDatas,
  checkFormAssignInEventForms,
  checkFormAssignInInitiatedForm,
  getStudyLevelFormsHistoryByID,
  deleteStudyLevelFormsData,
  getStudyLevelFormData,
  getStudyLevelFormField,
  getStudyLevelFormForReviewer,
  getStudyVisitFormData,
  getStudyVisitsFormForReviewer,
  getStudyLevelFormAttributeData,
  exportStudyLevelFormData,
  exportStudyLevelFormAuditTrialDataData,
  getStudySpecialFormData,
  getStudySpecialFormDataForReviewer,
  getdataEntryFormByIdForSpecialForm,
  getdataEntryFormByIdForOtherForm,
  addUnits,
  deleteUnitData,
  getTextUnit,
  getTextUnitForCRFView,
  getNumberUnit,
  getNumberUnitForCRFView,
  addConditionalLogic,
  getConditionalLoginData,
  deleteConditionalLogicData,
  getConditionalOptionList,
  getConditionalLogicData,
  getInitiatedDataEntryLists,
  getdataEntryFormStatus,
  getEntriedConditionalOptionId,
  getEntriedConditionalLogFormOptionId,
  updateCommentQuery,
  databaseUnFreezeForDataEntryForRegular,
  getFormFieldHistoryByIDLogForm,
  getDataEntryTicketsLogForm,
  getSingleConditionalLogic,
  getdataEntryFormByIdForSpecialLogForm,
  getdataEntryFormByIdForOtherLogForm,
  generateStudyFormQuery,
  getStudyFormQueryList,
  assignStudyFormQuery,
  updateStatusStudyFormQuery,
  getStudyFormQueryDiscussionList,
  sendStudyFormQueryDiscussion,
  filterStudyLevelFormData,
  filterStudySpecialFormData,
  filterStudySpecialFormDataForReviewer,
  reviewStudyLevelForm,
  uploadDocumentData
};

async function addUnits(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}addFieldUnits`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function addConditionalLogic(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}addConditionalLogic`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function updateCommentQuery(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}updateCommentQuery`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getTextUnit(formUniqueId, uniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getTextUnit/${formUniqueId}/${uniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getEntriedConditionalOptionId(studyId, formUniqueId, studyUniqueId, dataEntryFormMode, StudyMode) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getEntriedConditionalOptionId/${studyId}/${formUniqueId}/${studyUniqueId}/${dataEntryFormMode}/${StudyMode}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getEntriedConditionalLogFormOptionId(studyId, connectUniqueId, formUniqueId, studyUniqueId, dataEntryFormMode, StudyMode) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getEntriedConditionalLogFormOptionId/${studyId}/${connectUniqueId}/${formUniqueId}/${studyUniqueId}/${dataEntryFormMode}/${StudyMode}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getNumberUnit(formUniqueId, uniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getNumberUnit/${formUniqueId}/${uniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getTextUnitForCRFView(formUniqueId, uniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getTextUnitForCRFView/${formUniqueId}/${uniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getNumberUnitForCRFView(formUniqueId, uniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getNumberUnitForCRFView/${formUniqueId}/${uniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function createClientLevelForm(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}createStudyLevelFormMeta`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function databaseUnFreezeForDataEntry(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}databaseUnFreezeForDataEntry`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function databaseUnFreezeForDataEntryForRegular(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}databaseUnFreezeForDataEntryForRegular`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function createProjectMetaData(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}createProjectLevelFormMeta`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function updateLogFormData(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}updateLogFormData`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function duplicateFormForNextVersion(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}duplicateFormForNextVersion`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function updateStudyLevelFormsOptionsValue(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}updateStudyLevelFormsOptionsValue`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function logFormQueryGenerator(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}logFormQueryGenerator`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function reviewClientLogFormField(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}reviewClientLogFormField`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function sendForReviewStudyForm(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}sendForReviewStudyForm`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function createForms(datas, endPoint) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function updateQuery(endPoint, datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function replyQuery(endPoint, datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function queryGenerator(endPoint, datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getGlobalTickets(endPoint, uniqueId, fieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${uniqueId}/${fieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getDataEntryTickets(endPoint, uniqueId, StudyMode, dataEntryMode, studyUniqueId, fieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${uniqueId}/${StudyMode}/${dataEntryMode}/${studyUniqueId}/${fieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getDataEntryTicketsLogForm(endPoint, uniqueId, StudyMode, dataEntryMode, studyUniqueId, fieldId, connectUniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${uniqueId}/${StudyMode}/${dataEntryMode}/${studyUniqueId}/${fieldId}/${connectUniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getQueryList(endPoint, ticketId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${ticketId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getFormFieldHistoryByID(endPoint, formId, studyUniqueId ,dataEntryMode, StudyMode, fieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${studyUniqueId}/${dataEntryMode}/${StudyMode}/${fieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getFormFieldHistoryByIDLogForm(endPoint, formId, studyUniqueId ,dataEntryMode, StudyMode, fieldId, connectUniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${studyUniqueId}/${dataEntryMode}/${StudyMode}/${fieldId}/${connectUniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyFormFieldHistoryByID(endPoint, formId, fieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${fieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getConditionalLoginData(endPoint, formId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getSingleConditionalLogic(endPoint, formId, dataEntryMode) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${dataEntryMode}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function deleteConditionalLogicData(id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}deleteConditionalLogicData/${id}`,
      headersWithAuthWithoutBody("DELETE", headers)
    );
    const data = await response.json();
    handleResponse(data)
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function deleteUnitData(studyId, id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}deleteUnitData/${studyId}/${id}`,
      headersWithAuthWithoutBody("DELETE", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getInitiatedDataEntryLists(studyId, StudyMode) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getInitiatedDataEntryLists/${studyId}/${StudyMode}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getConditionalLogicData(uniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getConditionalLogicData/${uniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getConditionalOptionList(uniqueId, oprionId, studyId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getConditionalOptionList/${uniqueId}/${oprionId}/${studyId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getdataEntryFormByIdForSpecialForm(endPoint, formId, dataEntryMode, StudyMode, studyUniqueId, fieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${dataEntryMode}/${StudyMode}/${studyUniqueId}/${fieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getdataEntryFormByIdForSpecialLogForm(endPoint, formId, dataEntryMode, StudyMode, studyUniqueId, fieldId, connectUniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${dataEntryMode}/${StudyMode}/${studyUniqueId}/${fieldId}/${connectUniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getdataEntryFormStatus(dataEntryMode, studyUniqueId, StudyMode, formId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getdataEntryFormStatus/${dataEntryMode}/${studyUniqueId}/${StudyMode}/${formId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getdataEntryFormByIdForOtherForm(endPoint, formId, dataEntryMode, StudyMode, studyUniqueId, fieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${dataEntryMode}/${StudyMode}/${studyUniqueId}/${fieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getdataEntryFormByIdForOtherLogForm(endPoint, formId, dataEntryMode, StudyMode, studyUniqueId, fieldId, connectUniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${dataEntryMode}/${StudyMode}/${studyUniqueId}/${fieldId}/${connectUniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getDataEntryFreezeLock(endPoint, formId,studyUniqueId, dataEntryMode, StudyMode, fieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${studyUniqueId}/${dataEntryMode}/${StudyMode}/${fieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getDataEntryFreezeLockForLogFrom(endPoint, formId,studyUniqueId, dataEntryMode, StudyMode, fieldId, connectUniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${studyUniqueId}/${dataEntryMode}/${StudyMode}/${fieldId}/${connectUniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getReviewHistoryByID(endPoint, formId, StudyMode, fieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${StudyMode}/${fieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getDataEntryFormFieldValidation(formId, studyUniqueId, studyMode, dataEntryMode, fieldId, studyId, recordId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}/getDataEntryFormFieldValidation/${formId}/${studyUniqueId}/${studyMode}/${dataEntryMode}/${fieldId}/${studyId}/${recordId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getDataEntryReviewHistoryByID(endPoint, formId, dataEntryMode, studyUniqueId, StudyMode, fieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${dataEntryMode}/${studyUniqueId}/${StudyMode}/${fieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getLogFormFieldReviewHistoryByID(endPoint, formId, dataEntryMode, studyUniqueId, StudyMode, fieldId, connectUniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${dataEntryMode}/${studyUniqueId}/${StudyMode}/${fieldId}/${connectUniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getReviewHistoryByID1(endPoint, formId, fieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${fieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getFormFieldAttributeByID(endPoint, formId, fieldId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}${endPoint}/${formId}/${fieldId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyLevelFormData(studyId, page, limit) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyLevelFormData/${studyId}/${page}/${limit}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyLevelFormField(studyId, page, limit) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyLevelFormField/${studyId}/${page}/${limit}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyLevelFormForReviewer(studyId, page, limit) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyLevelFormForReviewer/${studyId}/${page}/${limit}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyVisitFormData(studyId,studyVisitId, page, limit) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyVisitsFormData/${studyId}/${studyVisitId}/${page}/${limit}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudySpecialFormData(studyId, studyUniqueId, StudyMode, page, limit) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudySpecialFormData/${studyId}/${studyUniqueId}/${StudyMode}/${page}/${limit}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudySpecialFormDataForReviewer(studyId, studyUniqueId, StudyMode, page, limit) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudySpecialFormDataForReviewer/${studyId}/${studyUniqueId}/${StudyMode}/${page}/${limit}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyVisitsFormForReviewer(studyId,studyVisitId, page, limit) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyVisitsFormForReviewer/${studyId}/${studyVisitId}/${page}/${limit}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyLevelFormReviewDatas(id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyLevelFormReviewData/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function checkFormAssignInEventForms(studyId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}checkFormAssignInEventForm/${studyId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function checkFormAssignInInitiatedForm(studyId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}checkFormAssignInInitiatedForm/${studyId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function updateStudyLevelForms(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}updateStudyLevelFormsMeta`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getStudyLevelFormsHistoryByID(id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyLevelFormsHistoryByID/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function deleteStudyLevelFormsData(id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}deleteStudyLevelFormsData/${id}`,
      headersWithAuthWithoutBody("DELETE", headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function exportStudyLevelFormData(studyId, extension) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}exportStudyLevelFormData/${studyId}/${extension}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    let url = config.BACKEND_URL + data?.data;
    saveAs(url, data?.data);
  } catch (error) {
    console.log(error);
  }
}

async function exportStudyLevelFormAuditTrialDataData(studyId, extension) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}exportStudyLevelFormAuditTrialDataData/${studyId}/${extension}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    let url = config.BACKEND_URL + data?.data;
    saveAs(url, data?.data);
  } catch (error) {
    console.log(error);
  }
}

async function getStudyLevelFormAttributeData(id) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getStudyLevelFormAttributeData/${id}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getdatabaseUnFreezeForDataEntry(formUniqueId, studyUniqueId, StudyMode, dataEntryMode, uniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getdatabaseUnFreezeForDataEntry/${formUniqueId}/${studyUniqueId}/${StudyMode}/${dataEntryMode}/${uniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getdatabaseUnFreezeForDataEntryForLog(formUniqueId, studyUniqueId, StudyMode, dataEntryMode, uniqueId, connectUniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getdatabaseUnFreezeForDataEntryForLog/${formUniqueId}/${studyUniqueId}/${StudyMode}/${dataEntryMode}/${uniqueId}/${connectUniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getLogDataEntryLists(formUniqueId, studyUniqueId, dataEntryMode, StudyMode, uniqueId) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}getLogDataEntryLists/${formUniqueId}/${studyUniqueId}/${dataEntryMode}/${StudyMode}/${uniqueId}`,
      headersWithAuthWithoutBody("GET", headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

//Generate Form Query
async function generateStudyFormQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}generateStudyFormQuery`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

//Generate Form Query
async function assignStudyFormQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignStudyFormQuery`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

//Update Status Form Query
async function updateStatusStudyFormQuery(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStatusStudyFormQuery`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

async function getStudyFormQueryList(formUniqueId, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyFormQueryList/${formUniqueId}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyFormQueryDiscussionList(queryId, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyFormQueryDiscussionList/${queryId}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Send Study Form Query Discussion
async function sendStudyFormQueryDiscussion(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}sendStudyFormQueryDiscussion`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Filter Study Level Form
async function filterStudyLevelFormData(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}filterStudyLevelFormData`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

//Filter Study Special Form
async function filterStudySpecialFormData(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}filterStudySpecialFormData`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

//Filter Study Special Form for Review
async function filterStudySpecialFormDataForReviewer(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}filterStudySpecialFormDataForReviewer`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

//Form Review
async function reviewStudyLevelForm(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}reviewStudyLevelForm`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function uploadDocumentData(formData) {
  const response = await axios.post(
    `${config.API_URL_USER_ONBOARDING}uploadDocumentData`,
    formData
  );
  const data = response?.data;
  return data;
}