import React from 'react'
import { Table } from 'react-bootstrap';
import UseToggle from '../../../Hooks/UseToggle';
// import RandomizedSite from './RandomizedRequestButtonData';
import RandomizedRequestButtonData from './RandomizedRequestButtonData';

const RandomizeTableData = ({ setIsSectionVisible }) => {
    const { toggle, setToggle } = UseToggle();

    const factorData = [
        {
            _id: "1",
            randomizationNo: "8",
            site: "SITE04: site04",
            subjectID: "00002",
            randomNumber: "00003",
            treatmentCode: "Treat001",
            treatmentLevel: "Reference",
            gender: "male",
            remarks: "randomize 4",
            randomizedOn: "2024-05-29 11:36:54 UTC",
            randomizedBy: "Gaurav Chauhan",
        },
    ];

    return (
        <>   <div
            className="text-center m-0"
            style={{ fontSize: "13px", color: "#333" }}
        >
            <Table className="custom-table ">
                <thead>
                    <tr>
                        <th>Randomization No.</th>
                        <th>Site</th>
                        <th>Subject-ID</th>
                        <th>Randomized Number</th>
                        <th>Treatment Code</th>
                        <th>Treatment Level</th>
                        <th>Gender</th>
                        <th>Remarks</th>
                        <th>Randomized on</th>
                        <th>Randomized by</th>
                        <th className='text-center'>Status</th>
                        <th className='text-center'>Request</th>
                    </tr>
                </thead>
                <tbody>
                    {factorData?.map((item, index) => (
                        <tr key={index} className='CP'>
                            <td onClick={() => setIsSectionVisible(true)}>{item.randomizationNo}</td>
                            <td onClick={() => setIsSectionVisible(true)}>{item.site}</td>
                            <td onClick={() => setIsSectionVisible(true)}>{item.subjectID}</td>
                            <td onClick={() => setIsSectionVisible(true)}>{item.randomNumber}</td>
                            <td onClick={() => setIsSectionVisible(true)}>{item.treatmentCode}</td>
                            <td onClick={() => setIsSectionVisible(true)}>{item.treatmentLevel}</td>
                            <td onClick={() => setIsSectionVisible(true)}>{item.gender}</td>
                            <td onClick={() => setIsSectionVisible(true)}>{item.remarks}</td>
                            <td onClick={() => setIsSectionVisible(true)}>{item.randomizedOn}</td>
                            <td onClick={() => setIsSectionVisible(true)}>{item.randomizedBy}</td>
                            <td className='text-success text-center' onClick={() => setIsSectionVisible(true)}>Blinding</td>
                            <td className="text-center">
                                <button
                                    className="text-white border-success bg-success border"
                                    style={{
                                        fontSize: 10,
                                        borderRadius: 3,
                                        padding: "2px 8px",
                                    }}
                                >
                                    Request
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
            <RandomizedRequestButtonData
                Show={!toggle}
                Hide={setToggle}
                Title={"Table Properties"}
            />
        </>
    )
}

export default RandomizeTableData