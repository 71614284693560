import config from "../config/config.json";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../Helpers/Responses";
import { headers } from "../Helpers/Headers";
export const elearningServices = {
  getEDCClientELearningUser,
  addStudyElearning,
  getStudyELearningData,
  updateStudyELearning,
  deleteStudyELearningData,
  updateStudyELearningStatus,
  assignStudyELearningUser,

  addStudyElearningQuestion,
  getStudyELearningQuestionData,
  updateStudyELearningQuestionData,
  updateStudyELearningQuestionStatus,
  deleteStudyELearningQuestionData,
};
//get e learning data
async function getEDCClientELearningUser(studyID, trainingId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getEDCClientELearningUser/${studyID}/${trainingId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
// add ElearningMeta data
async function addStudyElearning(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addStudyElearning`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
//get e learning data
async function getStudyELearningData(studyID) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyELearningData/${studyID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Update elearning data
async function updateStudyELearning(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyELearning`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function updateStudyELearningStatus(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyELearningStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function assignStudyELearningUser(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignStudyELearningUser`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//delete elearning data
async function deleteStudyELearningData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyELearningData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// add ElearningMeta data
async function addStudyElearningQuestion(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addStudyElearningQuestion`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
//get e learning data
async function getStudyELearningQuestionData(traningId, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyELearningQuestionData/${traningId}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Update elearning data
async function updateStudyELearningQuestionData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyELearningQuestionData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//Update elearning data
async function updateStudyELearningQuestionStatus(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyELearningQuestionStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

//delete elearning data
async function deleteStudyELearningQuestionData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyELearningQuestionData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}
