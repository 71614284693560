import React, { useRef, useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "../../../ETMF/Pagination/Pagination";

const NewFactorTableData = ({
  tableRef,
  onMouseDown,
  handleAddNewLearning,
}) => {
  const ref = useRef(null);

  const factorData = [
    {
      _id: "1",
      name: "factor 1",
      value: "34",
      createAt: "22-09-2024",
      updatedAt: "23-09-2024",
    },
    {
      _id: "3",
      name: "factor 2",
      value: "35",
      createAt: "22-09-2024",
      updatedAt: "23-09-2024",
    },
    {
      _id: "4",
      name: "factor 3",
      value: "36",
      createAt: "22-09-2024",
      updatedAt: "23-09-2024",
    },
    {
      _id: "5",
      name: "factor 4",
      value: "37",
      createAt: "22-09-2024",
      updatedAt: "23-09-2024",
    },
    {
      _id: "6",
      name: "factor 5",
      value: "38",
      createAt: "22-09-2024",
      updatedAt: "23-09-2024",
    },
    {
      _id: "7",
      name: "factor 6",
      value: "34",
      createAt: "22-09-2024",
      updatedAt: "23-09-2024",
    },
    {
      _id: "8",
      name: "factor 7",
      value: "34",
      createAt: "22-09-2024",
      updatedAt: "23-09-2024",
    },
  ];

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = factorData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(factorData?.length / itemsPerPage);

  return (
    <>
      <div
        className="overflow-auto"
        style={{ maxHeight: "calc(100vh - 142px)" }}
      >
        <Table
          ref={tableRef}
          id="resizable-table"
          striped
          className="custom-table"
          hover
        >
          <thead className="thead-sticky">
            <tr>
              <th
                onMouseDown={(e) => onMouseDown(e, 0)}
                style={{ width: "40px" }}
              >
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Factor Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)} style={{ width: 120 }}>
                value
              </th>
              <th style={{ width: 140 }}>Created Date | Time</th>
              <th style={{ width: 140 }}>Update Date | Time</th>
              <th style={{ width: 80 }} className="text-center">
                View
              </th>
              <th style={{ width: 80 }} className="text-center">
                Delete
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {result?.map((item, index, i) => (
              <>
                <tr key={index}>
                  <td>{index + 1 + startIndex}</td>
                  <td>{item.name}</td>
                  <td>{item.value}</td>
                  <td>{item.createAt}</td>
                  <td>{item.updatedAt}</td>
                  <td className="text-center">
                    <button
                      onClick={handleAddNewLearning}
                      className="text-white border-success bg-success border"
                      style={{
                        fontSize: 10,
                        borderRadius: 3,
                        padding: "2px 8px",
                      }}
                    >
                      View
                    </button>
                  </td>
                  <td className="text-center">
                    <button
                      // onClick={() => deleteStudyELearningData(item?._id)}
                      className="text-white border-danger bg-danger border"
                      style={{
                        fontSize: 10,
                        borderRadius: 3,
                        padding: "2px 8px",
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
        <div className="position-sticky bottom-0">
          <Pagination
            totalPage={totalPage}
            pageNumber={pageNumber}
            itemsPerPage={itemsPerPage}
            totalItems={factorData?.length}
            setPageNumber={setPageNumber}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default NewFactorTableData;
