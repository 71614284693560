import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";

const AddTreatmentData = () => {
  const [loader, setLoader] = useState("");

  return (
    <div>
      <div className="p-2">
        <Form.Group className="mb-2">
          <Form.Label className="mb-1 fw-bold">
            Name : <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter  Name"
            className="rounded-1 fontSize12"
            // value={trainingName}
            // onChange={(e) => setTrainingName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label className="mb-1 fw-bold">
            Weight : <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Weight"
            className="rounded-1 fontSize12"
            // value={trainingName}
            // onChange={(e) => setTrainingName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="text-end">
          <button
            className="submitbutton rounded-1"
            // disabled={!trainingName || !description}
            // onClick={addStudyElearning}
          >
            {loader ? (
              <Spinner className="me-2" style={{ width: 15, height: 15 }} />
            ) : (
              <i className="fa-solid fa-square-plus me-2"></i>
            )}
            Add
          </button>
        </Form.Group>
      </div>
    </div>
  );
};

export default AddTreatmentData;
