import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import UseToggle from "../../../Hooks/UseToggle";
import NewTrialParametersDesign from "./NewTrialParametersDesign";
import NewTrialDesignModal from "./TreatmentDesign/NewTrialDesignModal";
import NewTrialfactorsDesign from "./FactorDesign/NewTrialfactorsDesign";
import NewTrialTreatmentsDesign from "./TreatmentDesign/NewTrialTreatmentsDesign";

const NewTrialDesign = () => {
  const [activeButton, setActiveButton] = useState("treatments");
  const { toggle1, setToggle1 } = UseToggle();

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const renderStepDesign = () => {
    switch (activeButton) {
      case "treatments":
        return (
          <NewTrialTreatmentsDesign
            onNext={() => handleButtonClick("factors")}
          />
        );
      case "factors":
        return (
          <NewTrialfactorsDesign
            onNext={() => handleButtonClick("parameters")}
            onBack={() => handleButtonClick("treatments")}
          />
        );
      case "parameters":
        return (
          <NewTrialParametersDesign
            onBack={() => handleButtonClick("factors")}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="mb-1">
        <Container fluid>
          <Row>
            <div className="d-flex gap-2">
              <div className="parameters">
                <button
                  className={`m-0 w-100 ${activeButton === "treatments" ? "active" : ""
                    }`}
                  onClick={() => handleButtonClick("treatments")}
                >
                  Step 1: Treatments
                </button>
              </div>
              <div className="parameters">
                <button
                  className={`m-0 w-100 ${activeButton === "factors" ? "active" : ""
                    }`}
                  onClick={() => handleButtonClick("factors")}
                >
                  Step 2: Factors
                </button>
              </div>
              <div className="parameters">
                <button
                  className={`m-0 w-100 ${activeButton === "parameters" ? "active" : ""
                    }`}
                  onClick={() => handleButtonClick("parameters")}
                >
                  Step 3: Parameters
                </button>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="trial-design-container mb-1">{renderStepDesign()}</div>
      {/* <NewFactorListModal
        SHOW={!toggle}
        HIDE={setToggle}
        TITLE={"New factors"}
      /> */}
      <NewTrialDesignModal
        SHOW={!toggle1}
        HIDE={setToggle1}
        TITLE={"New Treatment"}
      />
    </>
  );
};

export default NewTrialDesign;
