import React from "react";
import { NavLink } from "react-router-dom";
import NewTrialDesign from "./NewTrialDesign";
import { useState } from "react";
import { HomeLineSection } from "../../Layouts/Layouts";

const TrialDesign = () => {
  const [showNewTrial, setShowNewtrial] = useState(false);

  const handleShowNewTrail = () => {
    setShowNewtrial(true);
  };

  return (
    <>
      {!showNewTrial ? (
        <div className="trial-design-container mb-1">
          <div className="trial-design-header">Trial Design</div>
          <div
            className="text-center m-0 py-2"
            style={{ fontSize: "13px", color: "#333" }}
          >
            This trial <b>does not have a design.</b>
          </div>
          <div
            className="trial-design-options text-center mb-3"
            style={{ fontSize: "13px" }}
          >
            Create{" "}
            <NavLink to={""} onClick={handleShowNewTrail}>
              Trial Design{" "}
            </NavLink>{" "}
            to make site.
          </div>
        </div>
      ) : (
        <div>
          <NewTrialDesign />
        </div>
      )}
    </>
  );
};

export default TrialDesign;
