import React, { useState } from "react";
import SplitPane from "react-split-pane";
import { SpinerDataLoader } from "../../../NoDataFoundTable/NoDataFoundTable";
import { Table } from "react-bootstrap";
import Pagination from "../../../ETMF/Pagination/Pagination";

const ELearningActivity = () => {
  const [loader, setLoadeer] = useState("");
  const data = Array.from({ length: 15 }, (v, i) => ({
    sr: i + 1,
    name: `User ${i + 1}`,
    userId: `U00${i + 1}`,
    assignDateTime: new Date().toLocaleString(),
  }));

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = data?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(data?.length / itemsPerPage);

  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "91%" }}
      >
        {loader ? (
          <SpinerDataLoader />
        ) : (
          <div className="table-container tableLibrarySection">
            <Table
              id="resizable-table"
              striped
              hover
              className="m-0 custom-table"
            >
              <thead className="thead-sticky top-0">
                <tr>
                  <th style={{width: 45}}>Sr.</th>
                  <th>Name</th>
                  <th>UserId</th>
                  <th>Assign Date | Time</th>
                  <th style={{width: 100}}>View Progress</th>
                  <th style={{width: 100}}>View Certificate</th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.sr}</td>
                    <td>{item.name}</td>
                    <td>{item.userId}</td>
                    <td>{item.assignDateTime}</td>
                    <td>
                      <button
                        className="text-white border-success bg-success border"
                        style={{
                          fontSize: 10,
                          borderRadius: 3,
                          padding: "2px 8px",
                        }}
                      >
                        View Progress
                      </button>
                    </td>
                    <td>
                      <button
                        className="text-white border-success bg-success border"
                        style={{
                          fontSize: 10,
                          borderRadius: 3,
                          padding: "2px 8px",
                        }}
                      >
                        View Certificate
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0">
              <Pagination
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={data?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        )}
      </SplitPane>
    </>
  );
};

export default ELearningActivity;
