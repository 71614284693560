import React from "react";
import ReactApexChart from "react-apexcharts";
import SplitPane from "react-split-pane";

const SubjectManagementStudyApexChart = () => {
  const series = [
    {
      name: "Actual",
      data: [12, 44, 54, 66, 81, 67],
    },
    {
      name: "Expected",
      data: [14, 54, 52, 65, 66, 70],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: false,
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: ["2011", "2012", "2013", "2014", "2015", "2016"],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
    colors: ["#103c5e", "#285476"],
  };

  return (
    <SplitPane
      split="vertical"
      minSize={20}
      defaultSize="100%"
      style={{ height: "95%" }}
    >
      <div id="chart" style={{ padding: 5 }}>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
    </SplitPane>
  );
};

export default SubjectManagementStudyApexChart;
