import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import SplitPane from "react-split-pane";
import SiteHeaderMenu from "./SiteHeaderMenu";
import StudyFilter from "./ActionFiles/StudyFilter";
import TableProperty from "./ActionFiles/TableProperty";
import SiteSignature from "./ActionFiles/SiteSignature";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../../NoDataFoundTable/NoDataFoundTable";
import UseToggle from "../../../../../../Hooks/UseToggle";
import SiteTabProperties from "./Properties/SiteTabProperties";
import AddUpdateSiteRows from "./ActionFiles/AddUpdateSiteRows";
import GenerateStudyQuery from "./ActionFiles/GenerateStudyQuery";
import AddUpdateSiteColumn from "./ActionFiles/AddUpdateSiteColumn";
import { studyOnboardingService } from "../../../../../../Services/studyServices/studySitesServices";
import StudySiteTableData from "./StudySiteTableData";

const StudyIDStudySite = ({
  findTableAndRegularViewData,
  tableRef,
  onMouseDown,
  handleAddNewTab,
  viewTypeData,
}) => {
  const {
    toggle,
    setToggle,
    toggle1,
    setToggle1,
    toggle2,
    setToggle2,
    toggle3,
    setToggle3,
  } = UseToggle();
  const { studyId } = useParams();
  const [id, setId] = useState("");
  const [siteId, setSiteId] = useState("");
  const [loader, setLoader] = useState(false);
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState(0);
  const [updatedType, setUpdatedType] = useState("");
  const [subjectLimit, setSubjectLimit] = useState("");
  const [siteUniqueId, setSiteUniqueId] = useState("");
  const [studyUniqueId, setStudyUniqueId] = useState("");
  const [selectedNames, setSelectedNames] = useState([]);
  const [customMessage, setCustomMessage] = useState("");
  const [studyDynamicRow, setStudyDynamicRow] = useState([]);
  const [metedataComment, setMetedataComment] = useState([]);
  const [subjectUniqueId, setSubjectUniqueId] = useState("");
  const [studyDynamicColumn, setStudyDynamicColumn] = useState([]);
  const [studyDynamicColumnID, setStudyDynamicColumnID] = useState([]);
  const [updateStudyDynamicRow, setUpdateStudyDynamicRow] = useState([]);
  let allArray = viewTypeData === "ctms"
    ? [
      "Site Id",
      "Number of Subject",
      "Status",
      "Query Status",
      "Query Date | Time",
    ]
    : ["Site Id", "Number of Subject"];

  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const studyPermissionData = JSON.parse(
    localStorage.getItem("studyPermissionData")
  );

  let LiveSite = studyPermissionData?.siteIdForLive;
  let SDVLiveSite = studyPermissionData?.siteIdForSDVLive;
  let QCSite = studyPermissionData?.siteIdForQC;
  let SDVQCSite = studyPermissionData?.siteIdForSDVQC;

  const limit = 100;

  const [formValues, setFormValues] = useState([]);
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = studyDynamicRow?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(studyDynamicRow?.length / itemsPerPage);

  const handleChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...formValues];
    newValues[i][name] = value;

    setFormValues(newValues);
  };

  const addFormFields = () => {
    setFormValues([...formValues, { name: "", columnValue: "", types: "" }]);
  };

  const removeFormFields = (e, i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);

    setFormValues(newFormValues);
  };

  const onChangeInput = (e, i) => {
    const { name, value } = e.target;

    let items1 = [...studyDynamicColumn];
    items1[i][name] = value;

    setStudyDynamicColumn(items1);
  };

  const getStudySiteColumnData = async () => {
    setLoader(true);
    let data = await studyOnboardingService.getStudySiteColumnData(studyId);
    let initialData = data?.data === undefined ? "" : data?.data;
    if (initialData.length > 0) {
      let finalData = initialData?.sort()?.reverse();
      setStudyDynamicColumn(Object.values(finalData?.[0].column));
      setUpdateStudyDynamicColumn(Object.values(finalData?.[0].column) || []);
      setStudyDynamicColumnID(finalData?.[0]._id);
    } else {
      setStudyDynamicColumn("");
      setStudyDynamicColumnID("");
    }
    setLoader(false);
  };

  const getStudySiteRowData = async (page) => {
    let currentPage = page ? page : 1;
    let data = await studyOnboardingService.getStudySiteRowData(
      studyId,
      studyDynamicColumnID,
      currentPage,
      limit
    );
    let initialData = Object.values(data?.data === undefined ? "" : data?.data);
    let finalData = initialData?.sort()?.reverse();
    if (StudyMode === "Live Mode") {
      let data = finalData?.filter((item) => LiveSite?.includes(item.siteId));
      setStudyDynamicRow(data);
    } else if (StudyMode === "SDV Live Mode") {
      let data = finalData?.filter((item) =>
        SDVLiveSite?.includes(item.siteId)
      );
      setStudyDynamicRow(data);
    } else if (StudyMode === "QC Mode") {
      let data = finalData?.filter((item) => QCSite?.includes(item.siteId));
      setStudyDynamicRow(data);
    } else if (StudyMode === "SDV QC Mode") {
      let data = finalData?.filter((item) => SDVQCSite?.includes(item.siteId));
      setStudyDynamicRow(data);
    } else {
      setStudyDynamicRow(finalData);
    }
  };

  const setIdUpdateRowTable = (
    id,
    studyUniqueId,
    dynamicRowData,
    siteId,
    subjectLimit
  ) => {
    setId(id);
    setStudyUniqueId(studyUniqueId);
    setUpdateStudyDynamicRow(Object.values(dynamicRowData));
    setSiteId(siteId);
    setSubjectLimit(subjectLimit);
    setUpdatedType("editRow");
  };

  const cleanForAddNewSiteData = () => {
    setId("");
    getStudySiteColumnData();
    setSiteId("");
    setSubjectLimit("");
    setStudyUniqueId("");
    setUpdatedType("addRow");
  };

  const createStudySiteColumn = async (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Add New Column for Study Site!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, create it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        let datas = {};
        setLoading(true);
        datas.studyID = studyId;
        datas.dynamicColumnData = formValues;
        let data = await studyOnboardingService.createStudySiteColumn(datas);
        if (data?.statusCode === 200) {
          getStudySiteColumnData();
          getTablePropertyData();
          setFormValues([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
        setStatusCode(data?.statusCode);
        setCustomMessage(data?.customMessage);
      }
    });
  };

  const updateStudySiteColumn = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Study Site Column!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        if (updateStudyDynamicColumn.length === 0) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "At least one value is required to update!",
          });
          return;
        }
        let datas = {};
        datas.studyID = studyId;
        datas.dynamicColumnData = updateStudyDynamicColumn;
        let data = await studyOnboardingService.updateStudySiteColumn(
          studyDynamicColumnID,
          datas
        );
        if (data.statusCode === 200) {
          getStudySiteRowData();
          getTablePropertyData();
          getStudySiteColumnData();
          setFormValues([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
        setStatusCode(data?.statusCode);
        setCustomMessage(data?.customMessage);
      }
    });
  };

  const AddStudySiteRow = async (e) => {
    cleanForAddNewSiteData();
    e.preventDefault();
    let datas = {};
    setLoading(true);
    datas.studyID = studyId;
    datas.siteId = siteId;
    datas.subjectLimit = subjectLimit;
    datas.columnId = studyDynamicColumnID;
    datas.dynamicColumnData = studyDynamicColumn;
    let result = await studyOnboardingService.AddStudySiteRow(datas);
    await getStudySiteRowData();
    if (result?.statusCode === 200) {
      cleanForAddNewSiteData();
      setLoading(false);
    } else {
      setLoading(false);
    }
    setStatusCode(result?.statusCode);
    setCustomMessage(result.customMessage);
  };

  const UpdateStudySiteRow = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Study Site Row!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        let datas = {};
        datas.studyID = studyId;
        datas.siteIdLabel = siteId;
        datas.subjectLimit = subjectLimit;
        datas.dynamicColumnData = updateStudyDynamicRow;
        datas.studyUniqueId = studyUniqueId;
        datas.history = metedataComment;
        let data = await studyOnboardingService.UpdateStudySiteRow(id, datas);
        await getStudySiteRowData();
        if (data?.statusCode === 200) {
          setLoading(false);
          cleanForAddNewSiteData();
        } else {
          setLoading(false);
        }
        setStatusCode(data?.statusCode);
        setCustomMessage(data?.customMessage);
      }
    });
  };

  const deleteStudySubjectRowDataBasedOnSite = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Study Site!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        let data =
          await studyOnboardingService.deleteStudySubjectRowDataBasedOnSite(id);
        if (data?.statusCode === 200) {
          await studyOnboardingService.deleteStudySiteRowData(id);
        }
        await getStudySiteRowData();
      }
    });
  };

  setTimeout(() => {
    setStatusCode(0);
    setCustomMessage("");
  }, 5000);

  // UPDATE COLUMN DATA ONCHANGE..........................................

  const [updateStudyDynamicColumn, setUpdateStudyDynamicColumn] = useState([]);

  const addUpdateOnChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...updateStudyDynamicColumn];
    newValues[i][name] = value;

    setUpdateStudyDynamicColumn(newValues);
  };

  const removeAddUpdateFields = (e, i) => {
    let newFormValues = [...updateStudyDynamicColumn];
    newFormValues.splice(i, 1);
    setUpdateStudyDynamicColumn(newFormValues);

    let originalColumnLength = studyDynamicColumn?.length;
    let finalIndex = i - originalColumnLength;
    if (finalIndex >= 0) {
      let newAddFormValues = [...formValues];
      newAddFormValues.splice(finalIndex, 1);
      setFormValues(newAddFormValues);
    }
  };

  // UPDATE ROW DATA ONCHANGE ..........................................

  const UpdateRowOnChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...updateStudyDynamicRow];
    newValues[i][name] = value;

    setUpdateStudyDynamicRow(newValues);
  };

  const UpdateHistoryOnChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...updateStudyDynamicRow];
    newValues[i][name] = value;

    setMetedataComment(newValues);
  };

  const getTablePropertyData = async () => {
    let data = await studyOnboardingService.getTablePropertyData(
      "studySite",
      studyDynamicColumnID,
      studyId
    );
    let columnProperty = data?.data?.columnData;
    let finalColumnProperty =
      columnProperty === undefined ? allArray : columnProperty;
    setSelectedNames(finalColumnProperty);
  };

  const addTableAndRegularView = async (e, viewType) => {
    let datas = {};
    datas.studyId = studyId;
    datas.category = "studySite";
    datas.StudyMode = StudyMode;
    datas.viewType = viewType;
    let data = await studyOnboardingService.addTableAndRegularView(datas);
    if (data.statusCode) {
      findTableAndRegularViewData("studySite");
    }
  };

  const setIdReviewRowTable = (studyUniqueId) => {
    setToggle3();
    setStudyUniqueId(studyUniqueId);
  };

  const seIdGenerateQuery = (studyUniqueId) => {
    setToggle2();
    setStudyUniqueId(studyUniqueId);
  };

  const [selectedStudySiteId, setSelectedStudySiteId] = useState("");
  const [studySubjectState, setStudySubjectState] = useState("");
  const [siteIdData, setSiteIdData] = useState("");

  const getStudyRightSiteData = (item) => {
    setSiteUniqueId(item.studyUniqueId);
    setStudySubjectState(item.studyUniqueId);
    setSelectedStudySiteId(item.siteIdLabel);
    setSiteIdData(item._id);
  };

  useEffect(() => {
    if (viewTypeData === "edc") {
      findTableAndRegularViewData("studySite");
    }
    setUpdateStudyDynamicColumn([...studyDynamicColumn, ...formValues] || []);
  }, [studyDynamicColumnID, formValues]);

  useEffect(() => {
    getTablePropertyData();
    getStudySiteRowData();
  }, [studyDynamicColumnID]);

  useEffect(() => {
    getStudySiteColumnData();
  }, []);

  return (
    <>
      {studyDynamicColumn && (
        <SiteHeaderMenu
          studyDynamicColumn={studyDynamicColumn}
          setUpdatedType={setUpdatedType}
          tableProperty={setToggle}
          filterData={setToggle1}
          studyDynamicRow={studyDynamicRow}
          updatedType={updatedType}
          cleanForAddNewSiteData={cleanForAddNewSiteData}
          addTableAndRegularView={addTableAndRegularView}
          filter={filter}
          setFilter={setFilter}
          getStudySiteRowData={getStudySiteRowData}
          viewTypeData={viewTypeData}
        />
      )}
      <SplitPane
        split="horizontal"
        style={{
          height: "calc(100vh - 187px)",
        }}
        className="position-relative"
        defaultSize={isSectionVisible ? "40%" : "100%"}
      >
        <SplitPane
          split="vertical"
          minSize={20}
          defaultSize={updatedType ? "30%" : "100%"}
        >
          {StudyMode === "DBP MODE" &&
            updatedType &&
            (updatedType === "addColumn" || updatedType === "editColumn" ? (
              <AddUpdateSiteColumn
                formValues={formValues}
                addHandleChange={handleChange}
                updateHandleChange={addUpdateOnChange}
                addFormFields={addFormFields}
                removeFormFields={removeFormFields}
                handleSubmit0={updateStudySiteColumn}
                handleSubmit={createStudySiteColumn}
                updateStudyDynamicColumn={updateStudyDynamicColumn}
                studyDynamicColumn={studyDynamicColumn}
                removeAddUpdateFields={removeAddUpdateFields}
                loader={loading}
                statusCode={statusCode}
                customMessage={customMessage}
              />
            ) : (
              (updatedType === "addRow" || updatedType === "editRow") &&
              studyDynamicColumn?.length > 0 && (
                <AddUpdateSiteRows
                  formValues={formValues}
                  updateHandleChange={UpdateRowOnChange}
                  addHandleChange={onChangeInput}
                  addFormFields={addFormFields}
                  removeFormFields={removeFormFields}
                  siteId={siteId}
                  setSiteId={setSiteId}
                  subjectLimit={subjectLimit}
                  setSubjectLimit={setSubjectLimit}
                  handleSubmit1={AddStudySiteRow}
                  handleSubmit2={UpdateStudySiteRow}
                  studyUniqueId={studyUniqueId}
                  studyDynamicColumnData={updateStudyDynamicRow}
                  studyAddDynamicColumnData={studyDynamicColumn}
                  cleanForAddNewSiteData={cleanForAddNewSiteData}
                  UpdateHistoryOnChange={UpdateHistoryOnChange}
                  onChangeInput={onChangeInput}
                  loading={loading}
                  statusCode={statusCode}
                  customMessage={customMessage}
                />
              )
            ))}
          {loader ? (
            <SpinerDataLoader />
          ) : studyDynamicColumn?.length === 0 ? (
            <NoDataFoundTable MSG="Site not found" />
          ) : (
            <StudySiteTableData
              handleAddNewTab={handleAddNewTab}
              studyDynamicColumn={studyDynamicColumn}
              hideColumn={selectedNames}
              studyDynamicRow={result}
              setIdUpdateRowTable={setIdUpdateRowTable}
              setSubjectUniqueId={setSubjectUniqueId}
              setIdReviewRowTable={setIdReviewRowTable}
              handleSubmit3={deleteStudySubjectRowDataBasedOnSite}
              getStudyRightSiteData={getStudyRightSiteData}
              setIsSectionVisible={setIsSectionVisible}
              tableRef={tableRef}
              onMouseDown={onMouseDown}
              totalPage={totalPage}
              startIndex={startIndex}
              pageNumber={pageNumber}
              itemsPerPage={itemsPerPage}
              totalItems={result.length}
              setPageNumber={setPageNumber}
              setItemsPerPage={setItemsPerPage}
              setUpdatedType={setUpdatedType}
              seIdGenerateQuery={seIdGenerateQuery}
              viewTypeData={viewTypeData}
            />
          )}
        </SplitPane>
        {isSectionVisible &&
          studyDynamicColumn?.length > 0 && (
            <SiteTabProperties
              handleAddNewTab={handleAddNewTab}
              siteUniqueId={siteUniqueId}
              subjectUniqueId={subjectUniqueId}
              tableRef={tableRef}
              onMouseDown={onMouseDown}
              setIsSectionVisible={setIsSectionVisible}
              getStudySiteRowData={getStudySiteRowData}
              selectedStudySiteId={selectedStudySiteId}
              studySubjectState={studySubjectState}
              siteIdData={siteIdData}
              viewTypeData={viewTypeData}
            />
          )}
      </SplitPane>
      <TableProperty
        Show={!toggle}
        Hide={setToggle}
        allArray={allArray}
        selectedNames={selectedNames}
        setSelectedNames={setSelectedNames}
        studyDynamicColumn={studyDynamicColumn}
        studyId={studyId}
        studyDynamicColumnID={studyDynamicColumnID}
        getStudySiteColumnData={getStudySiteColumnData}
        getStudySiteRowData={getStudySiteRowData}
        Title={"Table Property"}
        viewTypeData={viewTypeData}
      />
      <StudyFilter
        Show={!toggle1}
        Hide={setToggle1}
        Title={"Filter"}
        filter={filter}
        setFilter={setFilter}
        setStudyDynamicRow={setStudyDynamicRow}
        getStudySiteRowData={getStudySiteRowData}
        studyDynamicColumnID={studyDynamicColumnID}
        LiveSite={LiveSite}
        SDVLiveSite={SDVLiveSite}
        QCSite={QCSite}
        SDVQCSite={SDVQCSite}
        viewTypeData={viewTypeData}
      />
      <GenerateStudyQuery
        Show={!toggle2}
        Hide={setToggle2}
        studyUniqueId={studyUniqueId}
        getStudySiteRowData={getStudySiteRowData}
        Title={"Generate Query"}
        Submit="Generate"
      />
      <SiteSignature
        Show={!toggle3}
        Hide={setToggle3}
        studyUniqueId={studyUniqueId}
        Title={"Signature Required"}
      />
    </>
  );
};

export default StudyIDStudySite;
