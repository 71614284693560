import React, { useEffect, useState } from "react";
import { Form, Spinner, Table } from "react-bootstrap";
import SplitPane from "react-split-pane";
import { SpinerDataLoader } from "../../../NoDataFoundTable/NoDataFoundTable";
import { useParams } from "react-router";
import { elearningServices } from "../../../../Services/elearningServices";

const AssignUser = ({ trainingId }) => {
  const { studyId } = useParams()
  const [loader, setLoader] = useState("");
  const [buttonLoader, setButtonLoader] = useState("");
  const [userList, setUserList] = useState([])
  const [selectedIds, setSelectedIds] = useState([])

  const handleCheckboxChange = (id, isChecked) => {
    if (isChecked) {
      setSelectedIds((prev) => [...new Set([...prev, id])]);
    } else {
      setSelectedIds((prev) => prev.filter((selectedId) => selectedId !== id));
    }
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allIds = userList.map((user) => user.userId);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const isAllSelected = userList.length > 0 && selectedIds.length === userList.length;

  const getEDCClientUser = async () => {
    setLoader(true)
    let data = await elearningServices.getEDCClientELearningUser(studyId, trainingId)
    setUserList(data?.data || [])
    setSelectedIds(data?.assignedUser || [])
    setLoader(false)
  }

  const assignStudyELearningUser = async () => {
    let userData = {}
    userData.id = trainingId
    userData.users = selectedIds
    let data = await elearningServices.assignStudyELearningUser(userData)
    if (data?.statusCode === 200) {
      getEDCClientUser()
    }
  }

  useEffect(() => {
    getEDCClientUser();
  }, [trainingId]);
  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "91%" }}
      >
        {loader ? (
          <SpinerDataLoader />
        ) : (
          <>
            <Table
              id="resizable-table"
              striped
              hover
              className="m-0 custom-table"
            >
              <thead className="thead-sticky top-0">
                <tr>
                  <th style={{ width: 40 }}>
                    <Form.Check
                      checked={isAllSelected}
                      onChange={(e) => handleSelectAll(e.target.checked)}
                      type="checkbox" />
                  </th>
                  <th>Full Name</th>
                  <th>UserId</th>
                </tr>
              </thead>
              <tbody>
                {userList.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={selectedIds.includes(item.userId)}
                        onChange={(e) => handleCheckboxChange(item.userId, e.target.checked)}
                      />
                    </td>
                    <td>{item.fullName}</td>
                    <td>{item.userId}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0 w-100 p-2 text-end bg-white">
              <button 
              onClick={assignStudyELearningUser}
              className="submitbutton rounded-1 border">
                {buttonLoader ? (
                  <Spinner className="me-2" style={{ width: 15, height: 15 }} />
                ) : (
                  <i className="fa-solid fa-right-to-bracket me-2"></i>
                )}
                Assign
              </button>
            </div>
          </>
        )}
      </SplitPane>
    </>
  );
};

export default AssignUser;
