import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import SignOut from "./SignOut";
import "../../Styles/Navbar.scss";
import Profilers from "./Profilers";
import Profilers1 from "./Profilers1";
import SwitchAccess from "./SwitchAccess";
import BottomFilebar from "./BottomFilebar";
import ProfileActions from "./ProfileActions";
import client1 from "../image/navbar/client1.png";
import profile from "../image/navbar/profile.png";
import { userServices } from "../../Services/userServices";
import { studyOnboardingService } from "../../Services/studyServices/studyOnboarding";

const Navbar = ({
  noApps,
  Profiler,
  Profiler1,
  Dropdown1,
  Dropdown2,
  Dropdown3,
  Dropdown4,
  Dropdown5,
  Dropdown6,
  ToolsItem,
  Study_Tools,
  Tools_Library,
  RemoveDropdowns,
  FormDrop_GlobalForm,
  FormDrop_ClientLevelForm,
  FormDrop_ProjectLevelForm,
}) => {
  const { studyId } = useParams();
  const currentURL = window.location.href;
  const splitURL = currentURL.split("/");

  const [firstName, setFirstName] = useState([]);
  const [lastName, setLastName] = useState([]);
  const [clientID, setClientID] = useState([]);
  const [projectID, setProjectID] = useState([]);
  const [projectLabel, setProjectLabel] = useState("");
  const [projectMode, setProjectMode] = useState([]);
  const [timeZone, setTimeZone] = useState("");
  const [managementRole, setManagementRole] = useState("");

  const [image, setImage] = useState("");

  const getCurrentTimeZone = () => {
    const now = new Date();
    const timeZoneOffsetMinutes = now.getTimezoneOffset();
    const timeZoneOffsetHours = Math.abs(
      Math.floor(timeZoneOffsetMinutes / 60)
    );
    const timeZoneOffsetMinutesRemainder = Math.abs(timeZoneOffsetMinutes % 60);
    const timeZoneOffsetSign = timeZoneOffsetMinutes >= 0 ? "-" : "+";
    const formattedTimeZone = `GMT${timeZoneOffsetSign}${String(
      timeZoneOffsetHours
    ).padStart(2, "0")}:${String(timeZoneOffsetMinutesRemainder).padStart(
      2,
      "0"
    )}`;
    setTimeZone(formattedTimeZone);
  };

  useEffect(() => {
    profileData();
    getCurrentTimeZone();
  }, []);

  const profileData = async () => {
    const item1 = JSON.parse(localStorage.getItem("firstName"));
    const item2 = JSON.parse(localStorage.getItem("lastName"));

    if (item1) {
      setFirstName(item1);
    }

    if (item2) {
      setLastName(item2);
    }
  };

  const studyLogout = JSON.parse(localStorage.getItem("role"));
  const studyManagement = JSON.parse(localStorage.getItem("studyManagement"));
  const etmfStudyManagement = JSON.parse(
    localStorage.getItem("etmfStudyManagement")
  );

  const logout = async () => {
    await userServices.logout();
  };

  const bottomFilebarProps = {
    logout,
    Tools_Library,
    Study_Tools,
    ToolsItem,
    RemoveDropdowns,
    Dropdown1,
    Dropdown2,
    Dropdown3,
    Dropdown4,
    Dropdown5,
    Dropdown6,
    FormDrop_GlobalForm,
    FormDrop_ClientLevelForm,
    FormDrop_ProjectLevelForm,
  };

  useEffect(() => {
    const getProjectIdByStudyId = async () => {
      let projectId = await studyOnboardingService.getProjectIdByStudyId(
        studyId
      );
      setProjectID(projectId?.data?.project_id);
      setProjectLabel(projectId?.data?.projectLabel);
    };

    const currentDetails = async (e) => {
      let userData = await userServices.getCurrentUserDetails(e);
      setClientID(userData?.[0]?.user_id?.userId);
      setManagementRole(userData?.[1]?.management_name?.name);
      setImage(userData?.[0]?.user_id?.image);
    };

    currentDetails();
    getProjectIdByStudyId();
  }, [studyId]);

  return (
    <>
      <nav style={{ background: "var(--blue)", color: "#fff" }}>
        <div className="hstack justify-content-between border-bottom border-secondary pe-2 ps-2" style={{padding: "6.8px 0px"}}>
          <h4 className="text-white m-0">Galax®</h4>
          <div className="hstack justify-content-between gap-2">
            <span className="text-white">
              <i className="fa-solid fa-earth-asia me-1 text-info"></i>Timezone
              : {timeZone}
            </span>
            <span className="text-white">
              <i className="fa-solid fa-building-user me-1 text-info"></i>Logged in
              as :{" "}
              {studyLogout === "client"
                ? "Admin"
                : studyLogout === "user"
                ? managementRole
                : splitURL[3] === "etmf"
                ? etmfStudyManagement
                : studyManagement}
            </span>
            <div className="hstack gap-1">
            <ProfileActions
              ProfileImage={image}
              firstName={firstName}
              lastName={lastName}
            />
            {studyLogout === "client" ||
            studyLogout === "user" ||
            studyLogout === "super_user" ? (
              ""
            ) : (
              <SwitchAccess />
            )}
            <SignOut logout={logout} />
            </div>
          </div>
        </div>
        <div className="hstack justify-content-between filebar">
          <div style={{ padding: 7 }}>
            <BottomFilebar {...bottomFilebarProps} />
          </div>
          {Profiler && (
            <Profilers
              profile={profile}
              client1={client1}
              projectid={projectLabel}
            />
          )}

          {Profiler1 && (
            <Profilers1
              projectID={projectLabel}
              clientID={clientID}
              projectMode={projectMode}
              noApps={noApps}
            />
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
