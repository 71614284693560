import React, { useEffect, useState } from "react";
import moment from "moment";
import Moment from "react-moment";
import { FaEye } from "react-icons/fa";
import SplitPane from "react-split-pane";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../../NoDataFoundTable/NoDataFoundTable";
  import { ContextMenuTrigger } from "react-contextmenu";
  import { Tooltip as ReactTooltip } from "react-tooltip";
  import UseToggle from "../../../../../../Hooks/UseToggle";
  import { Modal, Offcanvas, Table } from "react-bootstrap";
import RightClick from "../../../../../LeftComponent/RightClick";
import { clientLevelFormServices } from "../../../../../../Services/formServices/clientLevelFormServices";

export const HistoryTable = ({
  formType,
  FormFieldHistoryEndpoint,
  formUniqueId,
  selectedFormId,
  dataEntryMode,
  selectedForm,
  studyUniqueId,
  connectUniqueIdData,
}) => {
  const [formFieldHistory, setFormFieldHistory] = useState([]);
  const [formFieldHistoryDetails, setFormFieldHistoryDetails] = useState([]);
  const StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const [textUnitList, setTextUnitList] = useState([]);
  const [showDetails, setShowDetails] = useState("");
  const [numberUnitList, setNumberUnitList] = useState([]);
  const [loader, setLoader] = useState(false);
  const { setToggle, toggle } = UseToggle();

  const getFormFieldHistory = async () => {
    let endPointData =
      formType === "studyform"
        ? clientLevelFormServices.getStudyFormFieldHistoryByID(
          FormFieldHistoryEndpoint,
          formUniqueId,
          selectedFormId
        )
        : connectUniqueIdData
          ? clientLevelFormServices.getFormFieldHistoryByIDLogForm(
            "getDataEntryFormFieldValueHistoryByIdForRegularForm",
            formUniqueId,
            studyUniqueId,
            dataEntryMode,
            StudyMode,
            selectedFormId,
            connectUniqueIdData
          )
          : clientLevelFormServices.getFormFieldHistoryByID(
            "getDataEntryFormFieldValueHistoryById",
            formUniqueId,
            studyUniqueId,
            dataEntryMode,
            StudyMode,
            selectedFormId
          );
    setLoader(true);
    let res = await endPointData;
    setFormFieldHistory(res?.data);
    setLoader(false);
  };

  const getTextUnit = async () => {
    let data = await clientLevelFormServices.getTextUnit(
      formUniqueId,
      selectedFormId
    );
    setTextUnitList(data?.data);
  };

  const getNumberUnit = async () => {
    let data = await clientLevelFormServices.getNumberUnit(
      formUniqueId,
      selectedFormId
    );
    setNumberUnitList(data?.data);
  };

  useEffect(() => {
    getFormFieldHistory();
    getTextUnit();
    getNumberUnit();
  }, [FormFieldHistoryEndpoint, formUniqueId, selectedFormId]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const showHistoryData = (e, data) => {
    handleShow();
    setFormFieldHistoryDetails(data);
  };

  const handleShowDetails = (e, item) => {
    setToggle();
    setShowDetails(item)
  };

  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "84%" }}
      >
        {formType === "studyform" ? (
          loader ? (
            <SpinerDataLoader />
          ) : formFieldHistory?.length > 0 ? (
            <Table striped className="m-0 custom-table">
              <thead className="thead-sticky">
                <tr className="align-baseline text-white lineheight">
                  <th>Form Name</th>
                  <th>Version</th>
                </tr>
              </thead>
              <tbody className="border-top-0">
                {formFieldHistory?.map(({ version, formName, data }, i) => (
                  <React.Fragment key={i}>
                    <tr>
                      <td>
                        <ContextMenuTrigger
                          id={`${i}`}
                          collect={() => ({ id: i })}
                        >
                          {formName}
                        </ContextMenuTrigger>
                        <RightClick
                          ContextId={`${i}`}
                          Menu1
                          MenuName1={"View History"}
                          icons1={<FaEye className="edit" />}
                          handleClick1={(e) => showHistoryData(e, data)}
                        />
                      </td>
                      <td>
                        <ContextMenuTrigger
                          id={`${i}`}
                          collect={() => ({ id: i })}
                        >
                          {version}
                        </ContextMenuTrigger>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          ) : (
            <NoDataFoundTable MSG="Form Field History Not Found!" />
          )
        ) : loader ? (
          <SpinerDataLoader />
        ) : formFieldHistory?.length > 0 ? (
          <Table striped className="m-0 custom-table">
            <thead className="thead-sticky">
              <tr className="align-baseline text-white lineheight">
                <th>Record ID</th>
                <th>{selectedForm.label}</th>
                {(textUnitList?.length > 0 || numberUnitList?.length > 0) && (
                  <th>Unit</th>
                )}
                <th>Version</th>
                <th>UserId</th>
                <th>Entry Date | Time</th>
              </tr>
            </thead>
            <tbody>
              {formFieldHistory?.map((item, index) => (
                <tr key={index} onClick={(e) => handleShowDetails(e, item)} className="CP">
                  <td>{item.recordId}</td>
                  <td>{item.veriableName}</td>
                  {(textUnitList?.length > 0 || numberUnitList?.length > 0) && (
                    <td>{item.unit}</td>
                  )}
                  <td>{item.version}</td>
                  <td>{item.userId}</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm">
                      {item.createdAt}
                    </Moment>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <NoDataFoundTable MSG="Form Field History not found!" />
        )}
      </SplitPane>
      <Modal
        show={show}
        className={show ? "" : "d-none"}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>History Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formFieldHistoryDetails.length > 0
            ? formFieldHistoryDetails.map((item, i) => (
              <Table id="resizeMe" striped bordered key={i}>
                <thead>
                  <tr>
                    <th className="text-center" colspan="2">
                      <div className="d-flex align-items-center justify-content-between">
                        <b>
                          Version {item.version} :{" "}
                          {item.version === 1
                            ? "New Field"
                            : item.updatedField}
                        </b>
                        <div>
                          <i className="fa-solid fa-user"></i>{" "}
                          <span data-tooltip-id="my-tooltip-1">
                            {item.updatedBy}
                          </span>{" "}
                          |{" "}
                          {item.version === "1" ? (
                            <ReactTooltip
                              id="my-tooltip-1"
                              place="top"
                              content="Created By"
                            />
                          ) : (
                            <ReactTooltip
                              id="my-tooltip-1"
                              place="top"
                              content="Upadted By"
                            />
                          )}
                          {item.version === "1" ? (
                            <ReactTooltip
                              id="my-tooltip-2"
                              place="top"
                              content="Created Date"
                            />
                          ) : (
                            <ReactTooltip
                              id="my-tooltip-2"
                              place="top"
                              content="Upadted Date"
                            />
                          )}
                          {item.version === "1" ? (
                            <ReactTooltip
                              id="my-tooltip-3"
                              place="top"
                              content="Created Time"
                            />
                          ) : (
                            <ReactTooltip
                              id="my-tooltip-3"
                              place="top"
                              content="Upadted Time"
                            />
                          )}
                          <i className="fa-solid fa-calendar-days"></i>{" "}
                          {item.version === "1" ? (
                            <span data-tooltip-id="my-tooltip-2">
                              {moment(item.createdAt).format("DD-MM-YYYY")}
                            </span>
                          ) : (
                            <span data-tooltip-id="my-tooltip-2">
                              {moment(item.updatedAt).format("DD-MM-YYYY")}
                            </span>
                          )}{" "}
                          | <i className="fa-solid fa-clock"></i>{" "}
                          {item.version === "1" ? (
                            <span data-tooltip-id="my-tooltip-3">
                              {moment(item.createdAt).format("HH:mm")}
                            </span>
                          ) : (
                            <span data-tooltip-id="my-tooltip-3">
                              {moment(item.updatedAt).format("HH:mm")}
                            </span>
                          )}
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Previous Value</b>
                    </td>
                    <td>{item.previousValue ? item.previousValue : "_"}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Current Value</b>
                    </td>
                    <td>{item.currentValue ? item.currentValue : "_"}</td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>Remark:</b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div
                        style={{ whiteSpace: "pre-wrap", lineHeight: "23px" }}
                      >
                        {item.remark ? item.remark : "_"}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            ))
            : ""}
        </Modal.Body>
      </Modal>

      <Offcanvas
        show={!toggle}
        onHide={setToggle}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="table-container tableLibrarySection">
            <Table
              id="resizable-table"
              striped
              className="m-0 custom-table"
              bordered
            >
              <thead className="thead-sticky">
                <tr>
                  <th >Attribute</th>
                  <th >Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Record ID</td>
                  <td>{showDetails?.recordId}</td>
                </tr>
                <tr>
                  <td>{selectedForm.label}</td>
                  <td style={{
                    whiteSpace: 'pre-wrap',
                    overflowY: 'auto',
                    maxHeight: '150px',
                    padding: '5px',
                  }}>
                    {showDetails?.veriableName}</td>
                </tr>
                <tr>
                  {(textUnitList?.length > 0 || numberUnitList?.length > 0) && (
                    <td>Unit</td>
                  )}
                  {(textUnitList?.length > 0 || numberUnitList?.length > 0) && (
                    <td>{showDetails?.unit}</td>
                  )}
                </tr>
                <tr>
                  <td>Version</td>
                  <td>{showDetails?.version}</td>
                </tr>
                <tr>
                  <td>User Id</td>
                  <td>{showDetails?.userId}</td>
                </tr>
                <tr>
                  <td>Date | Time</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm">{showDetails?.createdAt}</Moment></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
